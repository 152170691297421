import { Paragraph } from '@hexa-ui/components';
import AppModal from 'Components/AppModal/AppModal';
import { useSwitchWorkspaceContext } from 'Context/SwitchWorkspaceContext/SwitchWorkspaceContext';
import { TrackingContext } from 'Context/TrackingContext';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import { useCheckCurrentPage } from 'Hooks/useCheckCurrentPage/useCheckCurrentPage';
import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { byChangeButtonModalClick } from 'Utils/segmentEventByLocation/segmentEventByLocation';
import { Styled } from './ChangeWorkspaceModal.style';
import { ChangeWorkspaceModalProps } from './ChangeWorkspaceModal.types';
import { ChangeWorkspaceModalForm } from './ChangeWorkspaceModalForm/ChangeWorkspaceModalForm';

type ChangeWorkspaceModalSubmit = {
  workspaceId: string;
  segmentId: string;
  vendorId?: string;
  country?: string;
  vendorName?: string;
};

export function ChangeWorkspaceModal({
  isOpen,
  onClose,
}: Readonly<ChangeWorkspaceModalProps>): React.ReactElement {
  const { formatMessage, locale: SELECTED_LANGUAGE } = useIntl();

  const { data, isLoading } = useGetWorkspaces();
  const allWorkspaces = data?.workspaces || [];

  const history = useHistory();
  const location = useLocation();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      workspaceId: '',
      segmentId: '',
      vendorId: '',
      country: '',
    },
  });

  const { updateWorkspace } = useWorkspaceContext();

  const { triggerElementClicked } = useElementClickedEvent();
  const trackingContext = useContext(TrackingContext);

  const currentPage = useCheckCurrentPage();

  const { setSwitchWorkspace, updateWorkspaceSwitchInfo } = useSwitchWorkspaceContext();

  const onSubmit = (data: ChangeWorkspaceModalSubmit) => {
    const destinyWorkspace = allWorkspaces?.find((workspace) => workspace.id === data.workspaceId);

    triggerElementClicked({
      is_core_event: false,
      page_label:
        currentPage === 'EmbedPage'
          ? `${trackingContext?.themeName} - ${trackingContext?.reportName}`
          : byChangeButtonModalClick(currentPage)?.page_label,
      page_name: byChangeButtonModalClick(currentPage)?.page_name,
      element_location_type: 'MODAL',
      element_location_label: 'Change Workspace',
      element_location_name: 'CHANGE_WORKSPACE',
      element_name: 'CHANGE_WORKSPACE_BUTTON',
      element_label: 'Change',
      element_type: 'BUTTON',
      destiny_workspace_label: destinyWorkspace?.title['en-US'],
      destiny_workspace_tracking_id: destinyWorkspace?.trackingId,
      occurence_id: byChangeButtonModalClick(currentPage)?.occurence_id,
    });

    updateWorkspace(data);
    methods.reset();

    onClose();

    updateWorkspaceSwitchInfo(
      destinyWorkspace?.title[SELECTED_LANGUAGE] || /* istanbul ignore next */ '',
      data.vendorName
    );
    setSwitchWorkspace(true);

    if (location.pathname !== '/reports') {
      history.push('/reports', { from: window.location.href });
    }
  };

  const onCloseModal = () => {
    methods.reset();
    onClose();
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onCloseModal}
      onCancelButton={onCloseModal}
      buttonConfirmLabel="CHANGE_WORKSPACE_MODAL.CONFIRM_BUTTON"
      onConfirmButton={methods.handleSubmit(onSubmit)}
      title="CHANGE_WORKSPACE_MODAL.TITLE"
      disableConfirmButton={!methods.formState.isValid}
      css={{
        '@tablet': { minWidth: '517px' },
        width: '100vw',
        '@media (min-width: 600px)': {
          width: '60vw',
        },
        '@media (min-width: 900px)': {
          width: '40vw',
        },
        '@media (min-width: 1200px)': {
          width: '30vw',
        },
      }}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <Styled.Container>
          <Paragraph>{formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.DESCRIPTION' })}</Paragraph>
          <ChangeWorkspaceModalForm />
        </Styled.Container>
      </FormProvider>
    </AppModal>
  );
}
