import CSVDownloadType from 'Domain/CSVDownloadType';
import { CSVDownload } from 'Domain/NewMenuItem';
import { useState } from 'react';

interface UseHelpCenterReturn {
  onOpenModal: (index: number) => void;
  openCSVDownloadModal: boolean;
  closeCSVDownloadModal: () => void;
  indexCSVDownload: number;
}

/* istanbul ignore next */
export const useHelpCenter = (csvDownload: CSVDownload[]): UseHelpCenterReturn => {
  const [openCSVDownloadModal, setOpenCSVDownloadModal] = useState<boolean>(false);
  const [indexCSVDownload, setIndexCSVDownload] = useState<number>(0);

  const onOpenCsvModal = () => {
    if (
      csvDownload[indexCSVDownload] &&
      csvDownload[indexCSVDownload].type !== CSVDownloadType.BECOME_GOLD
    )
      setOpenCSVDownloadModal(true);
  };

  const onOpenModal = (indexStr = 0) => {
    setIndexCSVDownload(Number(indexStr));
    onOpenCsvModal();
  };

  const closeCSVDownloadModal = () => {
    setOpenCSVDownloadModal(false);
  };

  return {
    onOpenModal,
    openCSVDownloadModal,
    closeCSVDownloadModal,
    indexCSVDownload,
  };
};
