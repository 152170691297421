import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentProperties } from '../useSegmentProperties/useSegmentProperties';
import { LoadingTimeEventReturn } from './useLoadingTimeEvent.types';

interface RenderingProps {
  occurence_id: string;
  page_name: string;
  is_core_event: boolean;
}

export default function useLoadingTimeEvent(): LoadingTimeEventReturn {
  const {
    dataLoadedRenderingProcess,
    reportRenderedRenderingProcess,
    rederingStartedRenderingProcess,
    initRenderingProcess,
    interactionRenderedRenderingProcess,
  } = useSegment();

  const {
    app_display_name,
    app_id,
    origin_workspace_label,
    origin_workspace_tracking_id,
    page_url,
    user_affiliation,
    user_country,
    user_id,
    user_email,
    vendor_display_name,
    vendor_id,
    vendor_service_model,
    vendor_tier_name,
    origin_report_label,
    origin_report_tracking_id,
    origin_theme_label,
    origin_theme_tracking_id,
    page_label,
    vendor_country,
  } = useSegmentProperties();

  const commonProps = {
    app_display_name,
    app_id,
    page_label,
    page_url,
    user_country,
    user_email,
    user_id,
    user_affiliation,
    vendor_country,
    vendor_display_name,
    vendor_id,
    vendor_service_model,
    vendor_tier_name,
    origin_workspace_label,
    origin_workspace_tracking_id,
    origin_theme_label,
    origin_theme_tracking_id,
    origin_report_label,
    origin_report_tracking_id,
  };

  const triggerDataLoadedRendering = ({
    occurence_id,
    page_name,
    is_core_event,
  }: RenderingProps) => {
    dataLoadedRenderingProcess({ ...commonProps, occurence_id, page_name, is_core_event });
  };

  const triggerReportRenderedProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: RenderingProps) => {
    reportRenderedRenderingProcess({
      ...commonProps,
      occurence_id,
      page_name,
      is_core_event,
    });
  };

  const triggerRenderingStartedRenderingProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: RenderingProps) => {
    rederingStartedRenderingProcess({
      ...commonProps,
      occurence_id,
      page_name,
      is_core_event,
    });
  };

  const triggerInitRenderingProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    initRenderingProcess({
      ...commonProps,
      occurence_id,
      page_name,
      is_core_event,
    });
  };

  const triggerInteractionRenderedProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    interactionRenderedRenderingProcess({
      ...commonProps,
      occurence_id,
      page_name,
      is_core_event,
    });
  };

  return {
    triggerDataLoadedRendering,
    triggerReportRenderedProcess,
    triggerRenderingStartedRenderingProcess,
    triggerInitRenderingProcess,
    triggerInteractionRenderedProcess,
  };
}
