import { styled } from '@hexa-ui/theme';

export const SelectPeriodLabel = styled('div', {
  '.modal-description': {
    marginTop: '16px',
    color: 'rgba(0,0,0,0.56)',
    backgroundColor: '#FFF', // Needed to prevent shared component Typography from breaking the code
  },
});

export const AlertMessage = styled('div', {
  '.alert-message': {
    padding: '16px',
    width: '100%',
    margin: 'auto',
    marginTop: '24px',
    marginBottom: '24px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.16)',
  },
});

export const ContainerSelect = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@tablet': {
    flexDirection: 'row',
  },
});

export const Space = styled('div', {
  marginTop: '32px',
});

export const SelectInput = styled('div', {
  '.select-input': {
    color: 'rgba(0,0,0,0.55)',
    marginTop: '6px',
    border: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    paddingLeft: '16px',
    height: '48px',
    width: '100%',
    fontFamily: 'Work Sans',
    fontSize: '16px',
  },
});

export const SelectYear = styled('div', {
  fontFamily: 'Work Sans',
  '& h5': {
    color: '#000',
  },
  width: '100%',
  marginBottom: '8px',

  '@tablet': {
    width: '50%',
  },
});

export const SelectMonth = styled('div', {
  width: '100%',
  marginLeft: '0',
  marginTop: '8px',
  fontFamily: 'Work Sans',
  '& h5': {
    color: '#000',
  },

  '@tablet': {
    width: '50%',
  },

  '@media (min-width: 600px)': {
    marginLeft: '12px',
    marginTop: '0',
  },
});

export const SelectSeller = styled('div', {
  fontFamily: 'Work Sans',
  '& h5': {
    color: '#000',
  },
  width: '100%',
  marginBottom: '8px',

  '@tablet': {
    width: '100%',
  },
});
