import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { GetKPIsProps } from 'Domain/Kpis.types';
import { getKPIs } from './getKPIs';

export const useGetKPIs = ({
  country,
  buzzword,
  lang,
  reportIds,
}: GetKPIsProps): UseQueryResult<any> =>
  useQuery({
    queryKey: ['getKPIs', country, reportIds, lang, buzzword],
    queryFn: () => getKPIs({ country, reportIds, lang, buzzword }),
    enabled: (buzzword && buzzword.length > 1) || /* istanbul ignore next */ buzzword === '',
  });
