/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useTrackingService from 'Hooks/useTrackingService';

const useSegment = () => {
  const segmentAnalytics = useTrackingService().getAnalyticsTypewriter();

  const {
    pageViewed,
    elementClicked,
    fileProcessed,
    searchApplied,
    initRenderingProcess,
    dataLoadedRenderingProcess,
    rederingStartedRenderingProcess,
    reportRenderedRenderingProcess,
    interactionRenderedRenderingProcess,
  } = segmentAnalytics;

  return {
    pageViewed,
    elementClicked,
    fileProcessed,
    searchApplied,
    initRenderingProcess,
    dataLoadedRenderingProcess,
    rederingStartedRenderingProcess,
    reportRenderedRenderingProcess,
    interactionRenderedRenderingProcess,
  };
};

export default useSegment;
