import { styled } from '@hexa-ui/theme';

const Wrapper = styled('div', {});

const Item = styled('div', {
  padding: '16px 8px',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Content = styled('div', {
  padding: '0 8px 16px',
});

export const Styled = {
  Wrapper,
  Item,
  Content,
};
