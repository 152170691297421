/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Pagination, SearchField } from '@hexa-ui/components';
import { ChevronRight } from '@hexa-ui/icons';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { EmptyState } from 'Components/EmptyState/EmptyState';
import { HeaderContainer } from 'Components/HeaderContainer/HeaderContainer';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import MFERoutes from 'Config/MFERoutes';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import usePageViewed from 'Hooks/segment/usePageViewedEvent/usePageViewedEvent';
import useSearchAppliedEvent from 'Hooks/segment/useSearchAppliedEvent/useSearchAppliedEvent';
import useDebounce from 'Hooks/useDebounce/useDebounce';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { formatMenuItems } from 'Utils/formatMenuItems/formatMenuItems';
import { CustomReportTab, GetReportTabs } from 'Utils/getReportsTab/getReportsTab';
import iconMap from 'Utils/iconMap/iconMap';
import { Styled } from './ReportsListPage.style';

interface ReportsListPageProps {
  notFoundDataState?: boolean;
}

export function ReportsListPage({
  notFoundDataState,
}: Readonly<ReportsListPageProps>): React.ReactElement {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [searchThemeAndReport, setSearchThemeAndReport] = useState('');
  const { triggerPageViewed } = usePageViewed();
  const { preferredLanguage } = usePreferredLanguageV2();
  const DEBOUNCE_DELAY = 1000;
  const debouncedSearch = useDebounce(searchThemeAndReport, DEBOUNCE_DELAY);
  const { triggerSearchApplied } = useSearchAppliedEvent();
  const REPORT_LIST = 'Report List';
  const { workspaceData } = useWorkspaceContext();
  const location = useLocation();
  const { from } = location.state || { from: null };

  const workspaceId = workspaceData?.workspace?.id;
  const segmentId = workspaceData?.segment?.id;
  const segmentCountry = workspaceData?.segment?.country;

  const { data: workspacesData } = useGetWorkspaces();

  const { data, isLoading } = useGetReportsByWorkspace(
    workspaceId ?? '',
    segmentId ?? '',
    segmentCountry
  );

  useEffect(() => {
    if (!isLoading) {
      triggerPageViewed({
        app_display_name: 'Analytics',
        app_id: 'PARTNER-REPORTS',
        is_core_event: false,
        page_label: REPORT_LIST,
        page_name: 'REPORT_LIST',
        referrer: from,
        workspace_count: workspacesData?.workspaces?.length,
        theme_count: sortedMenuItems.length,
        origin_theme_tracking_id: null,
        origin_theme_label: null,
        report_count: filteredReports.length,
        origin_report_label: null,
        origin_report_tracking_id: null,
        has_bt_guide: null,
        occurence_id: '1.2',
        preferred_language: preferredLanguage ?? 'en-US',
      });
    }
  }, [isLoading]);

  const sortedMenuItems = formatMenuItems(data?.menuItems || []);
  const allReports = GetReportTabs(sortedMenuItems);

  const filteredReports = allReports.filter((item) =>
    item.fullName.toLowerCase().includes(searchThemeAndReport.toLowerCase())
  );

  useEffect(() => {
    if (debouncedSearch) {
      triggerSearchApplied({
        is_core_event: false,
        page_label: 'Report List',
        page_name: 'REPORT_LIST',
        search_query: debouncedSearch,
        number_of_results: filteredReports.length,
        occurence_id: '4.1',
      });
    }
  }, [debouncedSearch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchThemeAndReport(event.target.value);
    setCurrentPage(1);
  };

  const handleClear = () => {
    setSearchThemeAndReport('');
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = filteredReports.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const noFilteredData = filteredReports.length === 0;

  const { triggerElementClicked } = useElementClickedEvent();

  const handleClickItem = (report: CustomReportTab, index: number) => {
    const { themeName, themeSlug, themeTrackingId, reportName, reportSlug, reportTrackingId } =
      report;

    triggerElementClicked({
      is_core_event: true,
      page_label: REPORT_LIST,
      page_name: 'REPORT_LIST',
      element_location_type: 'LIST',
      element_location_name: 'REPORT_LIST',
      element_location_label: REPORT_LIST,
      element_label: `${themeName} - ${reportName}`,
      element_name: 'REPORT_OPTION',
      element_type: 'LIST_ITEM',
      element_position: index + 1,
      destiny_theme_label: themeName,
      destiny_theme_tracking_id: themeTrackingId,
      destiny_report_label: reportName,
      destiny_report_tracking_id: reportTrackingId,
      occurence_id: '2.6',
    });

    history.push(`/reports/embed/${themeSlug}/${reportSlug}`, { from: window.location.href });
  };

  const renderComponent = () => {
    if (noFilteredData || notFoundDataState) {
      return (
        <EmptyState
          css={{ padding: '$24 0', margin: '0 auto' }}
          title={formatMessage({ id: 'REPORTS_LIST_PAGE.NOT_FOUND_STATE.TITLE' })}
          text={formatMessage({ id: 'REPORTS_LIST_PAGE.NOT_FOUND_STATE.TEXT' })}
        />
      );
    }

    return (
      <>
        {paginatedData.map((report, index) => (
          <Styled.ReportsTable key={report.id} onClick={() => handleClickItem(report, index)}>
            <tbody>
              <Styled.RowWrapper>
                <Styled.FirstColumn>{iconMap[report.iconString]}</Styled.FirstColumn>
                <Styled.SecondColumn>{report.fullName}</Styled.SecondColumn>
                <Styled.ThirdColumn>
                  <ChevronRight size="medium" />
                </Styled.ThirdColumn>
              </Styled.RowWrapper>
            </tbody>
          </Styled.ReportsTable>
        ))}
        <Styled.PaginationWrapper>
          <Pagination
            current={currentPage}
            pageSize={10}
            total={filteredReports.length}
            showPageSizeSelector={false}
            onChange={changePage}
          />
        </Styled.PaginationWrapper>
      </>
    );
  };

  return (
    <PageContainer maxWidth="xl">
      <Sidebar reportsMenuItems={sortedMenuItems} />
      <HeaderContainer
        pageTitle={formatMessage({ id: 'REPORTS_LIST_PAGE.TITLE' })}
        goBackButtonUrl={MFERoutes.MAIN_PAGE}
      />
      <SearchField.Root
        width="min(400px, 100%)"
        value={searchThemeAndReport}
        onChange={handleSearch}
        onClear={handleClear}
        placeholder={formatMessage({ id: 'REPORTS_LIST_PAGE.SEARCH.PLACEHOLDER' })}
      />

      {!isLoading && (
        <Styled.ReportsQuantity>{`${filteredReports.length} ${formatMessage({
          id: 'REPORTS_LIST_PAGE.REPORTS_QUANTITY',
        })}`}</Styled.ReportsQuantity>
      )}

      {isLoading ? (
        <Loading type="container" />
      ) : (
        <Styled.Card
          border="medium"
          elevated="small"
          css={{
            marginTop: '24px',
          }}
        >
          {renderComponent()}
        </Styled.Card>
      )}
    </PageContainer>
  );
}
