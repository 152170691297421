import { Paragraph as ParagraphHexa } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Button = styled('button', {
  position: 'fixed',
  bottom: '24px',
  right: '24px',
  background: 'black',
  color: 'white',
  border: 'none',
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  fontSize: '24px',
  cursor: 'pointer',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 998,
  '&:hover': {
    color: 'yellow',
  },
  '&:hover span': {
    visibility: 'visible',
    opacity: 1,
  },
});

const FabWindow = styled('div', {
  position: 'fixed',
  bottom: '84px',
  right: '24px',
  width: '555px',
  backgroundColor: 'white',
  padding: '32px',
  borderRadius: '24px',
  boxShadow: '0px 0px 25px -4px rgba(0,0,0,0.3)',
  display: 'none',
  flexDirection: 'column',
  overflow: 'hidden',
  zIndex: 999,
  '@media (max-width: 650px)': {
    width: '100%',
    maxHeight: '70%',
    bottom: '0',
    borderRadius: '24px 24px 0 0',
    left: '0',
  },
  variants: {
    open: {
      true: {
        display: 'flex',
      },
    },
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const Header = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Content = styled('div', {});

const Paragraph = styled(ParagraphHexa, {
  color: 'white!important',
});

export const Styled = {
  Button,
  FabWindow,
  Wrapper,
  Header,
  Content,
  Paragraph,
};
