import { Button, Heading, IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';
import { ANALYTICS_ADMIN_GROUP } from 'Assets/Constants';
import MFERoutes from 'Config/MFERoutes';
import { useScreenSize } from 'Hooks/useScreenSize/useScreenSize';
import useUserGroups from 'Hooks/useUserGroups/useUserGroups';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import { BreadcrumbComponent } from './Breadcrumb/Breadcrumb';
import { Styled } from './HeaderContainer.style';
import { HeaderContainerProps } from './HeaderContainer.types';
import { WorkspaceSelectorCard } from './WorkspaceSelectorCard/WorkspaceSelectorCard';

export function HeaderContainer({
  pageTitle,
  goBackButtonUrl,
  showAnalyticsAdminButton = false,
  showBreadcrumb = true,
}: Readonly<HeaderContainerProps>): React.ReactElement {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const userGroups = useUserGroups();
  const hasAnalyticsAdminPermission = hasAdminPermission(userGroups, ANALYTICS_ADMIN_GROUP);

  /* istanbul ignore next */
  const handleGoBack = () => {
    history.push(goBackButtonUrl, { from: window.location.href });
  };

  /* istanbul ignore next */
  const handleClick = () => {
    history.push(MFERoutes.ANALYTICS_ADMIN, { from: window.location.href });
  };

  const [isMobile] = useScreenSize(['mobile']);

  return (
    <Styled.Wrapper>
      {showBreadcrumb && <BreadcrumbComponent />}

      <Styled.HeaderContainer>
        <Styled.TitleContainer>
          {goBackButtonUrl && (
            <IconButton variant="tertiary" icon={ArrowLeft} onClick={handleGoBack} />
          )}
          <Heading size="H2">{pageTitle}</Heading>
        </Styled.TitleContainer>

        {hasAnalyticsAdminPermission && showAnalyticsAdminButton && (
          <Button size={isMobile ? 'small' : 'medium'} onClick={handleClick}>
            {formatMessage({ id: 'HEADER.ANALYTICS_BUTTON' })}
          </Button>
        )}
        <WorkspaceSelectorCard />
      </Styled.HeaderContainer>
    </Styled.Wrapper>
  );
}
