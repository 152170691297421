/* eslint-disable @typescript-eslint/no-explicit-any */
import { TypeToast, usePreferredLanguageV2, useToast } from 'admin-portal-shared-services';
import ErrorPage from 'Components/ErrorPage/ErrorPage';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import SwitchWorkspacesLoading from 'Components/SwitchWorkspacesLoading/SwitchWorkspacesLoading';
import { useSwitchWorkspaceContext } from 'Context/SwitchWorkspaceContext/SwitchWorkspaceContext';
import { useWorkspaceContext, WorkspaceData } from 'Context/WorkspacesContext/WorkspacesContext';
import { MenuItem } from 'Domain/NewMenuItem';
import { Workspace } from 'Domain/Workspaces';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useBTGuideById } from 'Services/GetBTGuide/useBTGuide';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { formatMenuItems } from 'Utils/formatMenuItems/formatMenuItems';
import MainPageContent from './MainPageContent/MainPageContent';

interface MainPageRenderProps {
  AllWorkspacesIsLoading: boolean;
  allWorkspaces: Workspace[];
  isSwitchWorkspaceActive: boolean;
  workspaceSwitchInfo: any;
  isErrorWorkspaces: boolean;
  selectedWorkspace: WorkspaceData | undefined;
  btGuideUrl: any;
  sortedMenuItems: MenuItem[];
  AllReportsIsLoading: boolean;
}

function MainPageRender({
  AllWorkspacesIsLoading,
  allWorkspaces,
  isSwitchWorkspaceActive,
  workspaceSwitchInfo,
  isErrorWorkspaces,
  selectedWorkspace,
  sortedMenuItems,
  AllReportsIsLoading,
  btGuideUrl,
}: MainPageRenderProps) {
  const { formatMessage } = useIntl();

  if (!AllWorkspacesIsLoading && allWorkspaces?.length === 0) {
    return (
      <ErrorPage
        action="ANALYTICS_ADMIN"
        title={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.TITLE' })}
        message={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.DESCRIPTION' })}
      />
    );
  }

  if (isSwitchWorkspaceActive) {
    const { workspaceName, segmentName } = workspaceSwitchInfo;

    return (
      <PageContainer maxWidth="xl" sidebar={false}>
        <SwitchWorkspacesLoading workspaceTitle={workspaceName || ''} segmentTitle={segmentName} />
      </PageContainer>
    );
  }

  if (
    (isErrorWorkspaces || !selectedWorkspace?.workspace?.id || btGuideUrl === undefined) &&
    !isSwitchWorkspaceActive
  ) {
    return (
      <PageContainer maxWidth="xl" sidebar={false}>
        <Loading type="container" />
      </PageContainer>
    );
  }

  const hasBtGuide = btGuideUrl !== false;

  return (
    <PageContainer maxWidth="xl">
      <Sidebar reportsMenuItems={sortedMenuItems} />
      <MainPageContent
        sortedMenuItems={sortedMenuItems}
        selectedWorkspace={selectedWorkspace}
        isBTGuideError={!hasBtGuide}
        btGuideUrl={hasBtGuide ? btGuideUrl : { url: '' }}
        AllReportsIsLoading={AllReportsIsLoading}
        allWorkspaces={allWorkspaces}
      />
    </PageContainer>
  );
}

export function MainPage(): React.ReactElement {
  const { formatMessage } = useIntl();
  const { workspaceData: selectedWorkspace, tempWorkspaceId } = useWorkspaceContext();
  const { preferredLanguage } = usePreferredLanguageV2();
  const toastService = useToast();

  const { data: btGuideUrl, isLoading: isBtGuideLoading } = useBTGuideById(
    selectedWorkspace?.vendorId,
    selectedWorkspace?.country
  );

  const {
    data,
    isFetching: AllWorkspacesIsLoading,
    isError: isErrorWorkspaces,
  } = useGetWorkspaces();
  const allWorkspaces = data?.workspaces || [];

  const {
    data: AllReportsMenuItems,
    isLoading: AllReportsIsLoading,
    isFetching: AllReportsIsFetching,
  } = useGetReportsByWorkspace(
    selectedWorkspace?.workspace?.id,
    selectedWorkspace?.segment?.id,
    selectedWorkspace?.segment?.country
  );

  const { isSwitchWorkspaceActive, setSwitchWorkspace, workspaceSwitchInfo } =
    useSwitchWorkspaceContext();

  /* istanbul ignore next */
  const handleToast = (workspace: Workspace) => {
    if (tempWorkspaceId !== undefined && isSwitchWorkspaceActive) {
      toastService.notify({
        type: TypeToast.INFO,
        message: `${formatMessage({ id: 'REPORTS_LIST_PAGE.TOAST.FIRST_PART' })}  ${
          workspace?.title[preferredLanguage ?? 'en-US']
        }  ${
          preferredLanguage === 'en-US'
            ? formatMessage({
                id: 'REPORTS_LIST_PAGE.TOAST.LAST_PART',
              })
            : ''
        }`,
      });
    }
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (!selectedWorkspace?.workspace?.id) return;

    if (
      isSwitchWorkspaceActive &&
      !AllReportsIsFetching &&
      !AllReportsIsLoading &&
      !isBtGuideLoading
    ) {
      setSwitchWorkspace(false);
      handleToast(selectedWorkspace?.workspace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace, AllReportsIsFetching, AllReportsIsLoading, isBtGuideLoading]);

  /* istanbul ignore next */
  const sortedMenuItems = formatMenuItems(AllReportsMenuItems?.menuItems || []);

  return (
    <MainPageRender
      sortedMenuItems={sortedMenuItems}
      selectedWorkspace={selectedWorkspace}
      btGuideUrl={btGuideUrl}
      AllReportsIsLoading={AllReportsIsLoading}
      allWorkspaces={allWorkspaces}
      AllWorkspacesIsLoading={AllWorkspacesIsLoading}
      isErrorWorkspaces={isErrorWorkspaces}
      isSwitchWorkspaceActive={isSwitchWorkspaceActive}
      workspaceSwitchInfo={workspaceSwitchInfo}
    />
  );
}
