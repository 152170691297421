import { Card as HexaCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const DownloadIconWrapper = styled('div', {
  width: '32px',
  height: '32px',
  borderRadius: '64px',
  display: 'flex',
  justifyContent: 'center',
  background: 'var(--bg-button-tertiary-default, #14141414)',
  alignItems: 'center',
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  marginTop: '$6',
  marginBottom: '$6',
});

const CardDataset = styled(HexaCard, {
  width: '100%',
  gap: '8px',
  paddingBottom: '24px',
});

const ContainerTitle = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '24px',
  gap: '8px',
  paddingBottom: '24px',
});

export { CardDataset, Container, ContainerTitle, DownloadIconWrapper };
