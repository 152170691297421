import { SegmentedControl } from '@hexa-ui/components';
import { generateReportsPath } from 'Config/MFERoutes';
import useReportsEvents from 'Hooks/segment/useReportsEvents/useReportsEvents';
import useSelectedMenuItem from 'Hooks/useSelectedMenuItem/useSelectedMenuItem';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface ReportToggleProps {
  reportToggle: any;
}

const ReportToggle = React.forwardRef<HTMLButtonElement, ReportToggleProps>(
  ({ reportToggle }): React.ReactElement => {
    const { triggerTabInteraction } = useReportsEvents();
    const history = useHistory();
    const { selectedVendor, selectedCountry } = useSelectedVendor();
    const selectedMenuItem = useSelectedMenuItem();
    const selectedTab = useSelectedTab();
    const [allowTabInteraction, setAllowTabInteraction] = useState(false);

    const handleToggleClick = useCallback(
      (value: string) => {
        setAllowTabInteraction(true);

        history.push(
          {
            pathname: generateReportsPath({
              vendorId: selectedVendor.data?.id,
              tab: value,
              menuItem: selectedMenuItem.slug,
              country: selectedCountry,
            }),
          },
          { from: window.location.href }
        );
      },
      [
        triggerTabInteraction,
        selectedTab.reportCode,
        selectedTab.slug,
        history,
        selectedVendor.data?.id,
        selectedMenuItem.slug,
        selectedCountry,
      ]
    );

    useEffect(() => {
      const hasTabInteraction = allowTabInteraction;
      setAllowTabInteraction(false);
      if (hasTabInteraction) triggerTabInteraction();
    }, [history, allowTabInteraction, setAllowTabInteraction, triggerTabInteraction]);

    return (
      <SegmentedControl
        data-testid={`TOGGLE_${selectedTab.reportCode}`}
        defaultValue={reportToggle[0].value}
        options={reportToggle}
        onValueChange={(value) => handleToggleClick(value)}
      />
    );
  }
);

ReportToggle.displayName = 'ReportToggle';

export default ReportToggle;
