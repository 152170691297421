import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type WorkspaceSwitchInfoProps = {
  workspaceName?: string;
  segmentName?: string;
};

interface SwitchWorkspaceContextProps {
  isSwitchWorkspaceActive: boolean;
  setSwitchWorkspace: (value: boolean) => void;
  workspaceSwitchInfo: {
    workspaceName?: string;
    segmentName?: string;
  };
  updateWorkspaceSwitchInfo: (workspaceName: string, segmentName?: string) => void;
}

const SwitchWorkspaceContext = createContext({} as SwitchWorkspaceContextProps);

interface SwitchWorkspaceProviderProps {
  children: ReactNode;
}

function SwitchWorkspaceProvider({
  children,
}: Readonly<SwitchWorkspaceProviderProps>): JSX.Element {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [workspaceSwitchInfo, setWorkspaceSwitchInfo] = useState<WorkspaceSwitchInfoProps>({
    workspaceName: '',
    segmentName: '',
  });

  const setSwitchWorkspace = (value: boolean) => {
    setIsActive(value);
  };

  const updateWorkspaceSwitchInfo = (workspaceName?: string, segmentName?: string) => {
    setWorkspaceSwitchInfo({ workspaceName, segmentName });
  };

  const contextValue = useMemo(
    () => ({
      isSwitchWorkspaceActive: isActive,
      setSwitchWorkspace,
      workspaceSwitchInfo,
      updateWorkspaceSwitchInfo,
    }),
    [isActive, workspaceSwitchInfo]
  );

  return (
    <SwitchWorkspaceContext.Provider value={contextValue}>
      {children}
    </SwitchWorkspaceContext.Provider>
  );
}

const useSwitchWorkspaceContext = (): SwitchWorkspaceContextProps =>
  useContext(SwitchWorkspaceContext);

export { SwitchWorkspaceProvider, useSwitchWorkspaceContext };
