import { styled } from '@hexa-ui/theme';

const EmptyMessageWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '336px',
  textAlign: 'center',
});

const SearchFieldWrapper = styled('div', {
  marginBottom: '13px',
  marginLeft: '10px',
});

const ZebraTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  '& th': {
    padding: '10px',
    backgroundColor: '#F2F2F2',
    borderBottom: '1px solid #e0e0e0',
    textAlign: 'left',
    fontFamily: 'Barlow',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  '& th:last-child': {
    textAlign: 'left !important',
  },
  '& td': {
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
    fontFamily: 'Work Sans',
    fontsize: '14px',
    fontweight: 400,
    lineheight: '20px',
    textalign: 'left',
  },
  '& tr:nth-child(odd)': {
    backgroundColor: '#FFFFFF',
  },
  '& tr:nth-child(even)': {
    backgroundColor: '#FAFAFA',
  },
});

const PaginationWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'right',
  margin: '16px 16px 16px 0px',
});

export { EmptyMessageWrapper, PaginationWrapper, SearchFieldWrapper, ZebraTable };
