import { Paragraph as ParagraphHexa } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  maxHeight: '300px',
  paddingRight: '12px',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },

  scrollbarWidth: 'thin',
});

const KpiGroup = styled('div', {});

const KpiItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const Paragraph = styled(ParagraphHexa, {
  lineHeight: '20px!important',
});

const HighlightText = styled('span', {
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  padding: '4px 0',
});

export const Styled = {
  Wrapper,
  Container,
  KpiGroup,
  KpiItem,
  Paragraph,
  HighlightText,
};
