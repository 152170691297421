import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { GetKPIsProps, GetKPIsResponse } from 'Domain/Kpis.types';

export const getKPIs = async ({
  country,
  buzzword,
  lang,
  reportIds,
}: GetKPIsProps): Promise<any> => {
  const BASE_URL = getAPIBaseURL(APIEndpoint.GET_KPIS, country);

  const response = await Api.post({
    url: `/kpi`,
    baseURL: BASE_URL,
    body: {
      reportIds,
      lang,
      buzzword,
    },
  });

  return response.data as GetKPIsResponse;
};
