import { Paragraph } from '@hexa-ui/components';
import React from 'react';
import { Styled } from './FabTooltip.style';
import { TooltipProps } from './FabTooltip.types';

export function FabTooltip({ children }: TooltipProps): JSX.Element {
  return (
    <Styled.Tooltip data-testid="fab-tooltip">
      <Styled.TooltipContent>
        <Paragraph size="small" css={{ color: 'white' }}>
          {children}
        </Paragraph>
      </Styled.TooltipContent>
      <Styled.TooltipArrow />
    </Styled.Tooltip>
  );
}
