import { Card, Heading } from '@hexa-ui/components';
import CardSection from 'Components/CardSection/CardSection';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import React from 'react';
import { useIntl } from 'react-intl';
import Challenge from '../../assets/images/challenge.svg';
import { Styled } from './BTGuideSection.style';

type BTGuideSectionProps = {
  btGuideUrl: string;
};

function BTGuideSection({ btGuideUrl }: Readonly<BTGuideSectionProps>): React.ReactElement {
  const { formatMessage } = useIntl();
  const BT_GUIDE_LINK = formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.LINK' });

  const { triggerElementClicked } = useElementClickedEvent();

  const triggerEventAndRedirect = () => {
    triggerElementClicked({
      is_core_event: false,
      page_label: 'Main Page',
      page_name: 'MAIN_PAGE',
      element_location_type: 'SECTION',
      element_location_name: 'BT_GUIDE',
      element_location_label: 'Business Transformation Guide',
      element_label: 'Go to Business transformation guide',
      element_name: 'BT_GUIDE_LINK',
      element_type: 'LINK',
      destiny_report_label: 'Business Transformation Guide',
      destiny_report_tracking_id: 'BT_GUIDE',
      occurence_id: '2.2',
    });

    /* istanbul ignore next */
    setTimeout(() => {
      window.open(btGuideUrl, '_blank');
    }, 100);
  };

  return (
    <CardSection title={formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.TITLE' })}>
      <Styled.Content>
        <Card
          border="medium"
          elevated="small"
          css={{
            padding: '24px',
            boxShadow: 'none',
            backgroundColor: '#FAFAFA',
            border: 'solid 1px rgba(20, 20, 20, 0.12)',
          }}
        >
          <Styled.ImageAndTextWrapper>
            <img src={Challenge} alt="challenge" />
            <Heading size="H4">
              {formatMessage({ id: 'MAIN_PAGE.BTGUIDE_SECTION.DESCRIPTION' })}
            </Heading>
          </Styled.ImageAndTextWrapper>
        </Card>

        <Styled.BtGuideButton
          onClick={triggerEventAndRedirect}
          data-testid="bt_guide_redirect"
          hasUnderline={false}
          size="small"
        >
          {BT_GUIDE_LINK}
        </Styled.BtGuideButton>
      </Styled.Content>
    </CardSection>
  );
}

export default BTGuideSection;
