export interface Kpi {
  name: string;
  description: string;
  methodology: string;
}

export interface Kpis {
  reportId: string;
  reportGroupTitle: string;
  kpis: Kpi[];
}

export interface KpisResponse {
  content: Kpis[];
}

export const MOCK_KPIS: KpisResponse = {
  content: [
    {
      reportId: '1111',
      reportGroupTitle: 'Business & Behavior',
      kpis: [
        {
          name: 'Average Gross Per POC',
          description:
            'This KPI measures the average gross revenue generated per Point of Contact (POC), expressed in US dollars (USD).',
          methodology:
            'The total gross revenue is summed and divided by the total number of Points of Contact (POCs) over a specific period to calculate the average.',
        },
        {
          name: 'Average per Satisfaction',
          description:
            'Evaluates the level of customer satisfaction based on feedback surveys, on a scale of 1 to 10.',
          methodology:
            'Data is collected through satisfaction surveys sent to customers after their interaction with the service. The average score is calculated based on the responses received.',
        },
        {
          name: 'Customer Lifetime Value (CLV)',
          description:
            'Estimates the total revenue expected from a customer over the entire duration of their relationship with the company.',
          methodology:
            'CLV is calculated by multiplying the average purchase value, purchase frequency, and the average customer lifespan.',
        },
        {
          name: 'Customer Retention Rate',
          description:
            'Measures the percentage of customers who continue to use the service over a specific period.',
          methodology:
            'The retention rate is calculated by dividing the number of customers at the end of the period by the number of customers at the start of the period, then multiplying by 100.',
        },
      ],
    },
    {
      reportId: '2222',
      reportGroupTitle: 'Key Performance Indicators (KPIs)',
      kpis: [
        {
          name: 'Net Promoter Score (NPS)',
          description:
            'Measures the likelihood of customers recommending the company to others, on a scale of -100 to 100.',
          methodology:
            'Customers are asked, on a scale of 0 to 10, how likely they are to recommend the company. Responses are categorized into Promoters (9-10), Passives (7-8), and Detractors (0-6). The NPS is calculated by subtracting the percentage of Detractors from the percentage of Promoters.',
        },
        {
          name: 'Monthly Recurring Revenue (MRR)',
          description:
            'Indicates the monthly recurring revenue generated from subscription contracts.',
          methodology:
            'The revenue generated from all active subscription contracts in a specific month is summed to obtain the MRR. Adjustments are made for new subscriptions, renewals, and cancellations.',
        },
        {
          name: 'Customer Acquisition Cost (CAC)',
          description: 'Measures the cost associated with acquiring a new customer.',
          methodology:
            'CAC is calculated by dividing the total marketing and sales expenses by the number of new customers acquired during a specific period.',
        },
        {
          name: 'Gross Margin',
          description:
            'Indicates the percentage of total revenue that exceeds the cost of goods sold (COGS).',
          methodology:
            'Gross Margin is calculated by subtracting COGS from total revenue, then dividing by total revenue and multiplying by 100 to get a percentage.',
        },
      ],
    },
    {
      reportId: '3333',
      reportGroupTitle: 'Operational Efficiency',
      kpis: [
        {
          name: 'Customer Churn Rate',
          description:
            'Measures the rate at which customers cancel their subscriptions over a specific period.',
          methodology:
            'The churn rate is calculated by dividing the number of customers lost by the total number of customers at the beginning of the period and multiplying the result by 100 to obtain a percentage.',
        },
        {
          name: 'First Contact Resolution (FCR)',
          description:
            'Evaluates the effectiveness of the support team in resolving customer issues on the first interaction.',
          methodology:
            'The FCR is calculated by dividing the number of cases resolved on the first interaction by the total number of cases received and multiplying the result by 100 to obtain a percentage.',
        },
        {
          name: 'Average Handle Time (AHT)',
          description: 'Measures the average duration of a customer service interaction.',
          methodology:
            'AHT is calculated by adding the total talk time, hold time, and follow-up time, then dividing by the number of interactions.',
        },
        {
          name: 'Service Level Agreement (SLA) Compliance',
          description:
            'Indicates the percentage of interactions that meet the predefined service level agreements.',
          methodology:
            'SLA Compliance is calculated by dividing the number of interactions that meet the SLA by the total number of interactions, then multiplying by 100.',
        },
      ],
    },
    {
      reportId: '4444',
      reportGroupTitle: 'Financial Metrics',
      kpis: [
        {
          name: 'Revenue Growth Rate',
          description:
            'Measures the rate at which a companys revenue is increasing over a specific period.',
          methodology:
            'Revenue Growth Rate is calculated by subtracting the revenue at the beginning of the period from the revenue at the end of the period, then dividing by the revenue at the beginning of the period and multiplying by 100.',
        },
        {
          name: 'Operating Income',
          description: 'Indicates the profit realized from a companys ongoing operations.',
          methodology:
            'Operating Income is calculated by subtracting operating expenses from gross income.',
        },
        {
          name: 'Earnings Before Interest, Taxes, Depreciation, and Amortization (EBITDA)',
          description:
            'Measures a companys overall financial performance and is used as an alternative to net income in some circumstances.',
          methodology:
            'EBITDA is calculated by adding back interest, taxes, depreciation, and amortization to net income.',
        },
        {
          name: 'Quick Ratio',
          description:
            'Indicates a companys short-term liquidity position and its ability to meet its short-term obligations with its most liquid assets.',
          methodology:
            'Quick Ratio is calculated by dividing liquid assets (cash, marketable securities, and receivables) by current liabilities.',
        },
      ],
    },
    {
      reportId: '5555',
      reportGroupTitle: 'Marketing Metrics',
      kpis: [
        {
          name: 'Website Traffic',
          description: 'Measures the number of visitors to a website over a specific period.',
          methodology:
            'Website Traffic is tracked using web analytics tools that count the number of unique visitors and page views.',
        },
        {
          name: 'Conversion Rate',
          description:
            'Indicates the percentage of website visitors who complete a desired action, such as making a purchase or filling out a form.',
          methodology:
            'Conversion Rate is calculated by dividing the number of conversions by the total number of visitors and multiplying by 100.',
        },
        {
          name: 'Cost Per Click (CPC)',
          description: 'Measures the cost incurred for each click on a digital advertisement.',
          methodology:
            'CPC is calculated by dividing the total cost of the advertisement by the number of clicks received.',
        },
        {
          name: 'Return on Marketing Investment (ROMI)',
          description: 'Indicates the revenue generated for every dollar spent on marketing.',
          methodology:
            'ROMI is calculated by subtracting the marketing costs from the revenue generated and dividing by the marketing costs.',
        },
      ],
    },
  ],
};
