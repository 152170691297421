import { Heading, IconButton, Paragraph } from '@hexa-ui/components';
import { HelpCircle, X } from '@hexa-ui/icons';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import { useCheckCurrentPage } from 'Hooks/useCheckCurrentPage/useCheckCurrentPage';
import { useReports } from 'Hooks/useReports/useReports';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetKPIs } from 'Services/GetKPIs/useGetkPIs';
import { byFABClick } from 'Utils/segmentEventByLocation/segmentEventByLocation';
import { FabContent } from './FabContent/FabContent';
import { FabTooltip } from './FabTooltip/FabTooltip';
import { Styled } from './FloatingActionButton.style';

export function FloatingActionButton(): JSX.Element {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHoveringModal, setIsHoveringModal] = useState<boolean>(false);
  const { reportsIds } = useReports();
  const { workspaceData: selectedWorkspace } = useWorkspaceContext();
  const { preferredLanguage } = usePreferredLanguageV2();

  const hasReports = !!reportsIds.length;
  const { triggerElementClicked } = useElementClickedEvent();

  const windowRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { reportBySlug, themeSelected } = useReports();
  const themeTitle = themeSelected?.title['en-US'];

  const currentPage = useCheckCurrentPage();

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
    triggerElementClicked({
      is_core_event: false,
      page_label:
        currentPage === 'EmbedPage'
          ? `${themeTitle} - ${reportBySlug?.title['en-US']}`
          : byFABClick(currentPage)?.page_label,
      page_name: byFABClick(currentPage)?.page_name,
      element_location_type: 'FAB',
      element_location_name: byFABClick(currentPage)?.page_name,
      element_location_label:
        currentPage === 'EmbedPage'
          ? `${themeTitle} - ${reportBySlug?.title['en-US']}`
          : byFABClick(currentPage)?.page_label,
      element_label: 'BEES Assistant',
      element_name: 'FLOATING_ACTION_BUTTON',
      element_type: 'BUTTON',
      origin_theme_label: currentPage === 'EmbedPage' ? themeTitle : null,
      origin_theme_tracking_id: currentPage === 'EmbedPage' ? themeSelected?.trackingId : null,
      origin_report_label: currentPage === 'EmbedPage' ? reportBySlug?.title['en-US'] : null,
      origin_report_tracking_id: currentPage === 'EmbedPage' ? reportBySlug?.trackingId : null,
      occurence_id: byFABClick(currentPage)?.occurence_id,
    });
  };

  const { data } = useGetKPIs({
    country: selectedWorkspace?.segment?.country,
    buzzword: '',
    lang: preferredLanguage ?? 'en-US',
    reportIds: reportsIds,
  });

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      windowRef.current &&
      !windowRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  /* istanbul ignore next */
  const handleWheel = (event: WheelEvent): void => {
    if (isHoveringModal && windowRef.current) {
      const target = event.target as HTMLElement;
      const isScrollingInsideContent = contentRef.current?.contains(target);

      if (isScrollingInsideContent && contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

        const isAtTop = scrollTop === 0 && event.deltaY < 0;
        const isAtBottom = scrollTop + clientHeight === scrollHeight && event.deltaY > 0;

        if (isAtTop || isAtBottom) {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('wheel', handleWheel, { passive: false });
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('wheel', handleWheel);
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('wheel', handleWheel);
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isHoveringModal]);

  return data?.kpis?.length >= 1 ? (
    <>
      {hasReports && (
        <Styled.Button ref={buttonRef} onClick={handleToggle} data-testid="floating-action-button">
          <HelpCircle />
          <FabTooltip>
            <Styled.Paragraph>{formatMessage({ id: 'FAB.TOOLTIP_MESSAGE_1' })}</Styled.Paragraph>
            <Styled.Paragraph>{formatMessage({ id: 'FAB.TOOLTIP_MESSAGE_2' })}</Styled.Paragraph>
          </FabTooltip>
        </Styled.Button>
      )}

      {isOpen && (
        <Styled.FabWindow
          ref={windowRef}
          open={isOpen}
          onMouseEnter={() => setIsHoveringModal(true)}
          onMouseLeave={() => setIsHoveringModal(false)}
          data-testid="fab-window"
        >
          <Styled.Wrapper>
            <Styled.Header>
              <Heading size="H3">{formatMessage({ id: 'FAB.TITLE' })}</Heading>
              <IconButton
                data-testid="close-button"
                variant="tertiary"
                size="small"
                icon={X}
                onClick={handleToggle}
              />
            </Styled.Header>
            <Paragraph css={{ opacity: '56%' }}>
              {formatMessage({ id: 'FAB.DESCRIPTION' })}
            </Paragraph>
            <FabContent contentRef={contentRef} />
          </Styled.Wrapper>
        </Styled.FabWindow>
      )}
    </>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}
