import { Divider, Heading, Paragraph, SearchField } from '@hexa-ui/components';
import ExpandableCard from 'Components/ExpandableCard/ExpandableCard';
import { Kpi, MOCK_KPIS } from 'Domain/Kpis';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatPlural } from 'Utils/formatPlural/formatPlural';
import { Styled } from './FabContent.style';

interface Results {
  reportGroupTitle: string;
  kpis: Kpi[];
}

export function FabContent(): JSX.Element {
  const { formatMessage } = useIntl();

  const [searchKpi, setSearchKpi] = useState('');
  const [searchResults, setSearchResults] = useState<Results[]>([]);
  const [expandedIndex, setExpandedIndex] = useState<string | null>(null);

  const handleClearKpi = () => {
    setSearchKpi('');
    setSearchResults([]);
    setExpandedIndex(null);
  };

  const handleSearchKpi = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKpi(value);
    updateSearchResults(value);
    setExpandedIndex(null);
  }, []);

  const updateSearchResults = (searchTerm: string) => {
    if (searchTerm === '') {
      setSearchResults([]);
      return;
    }

    const results = MOCK_KPIS.content.reduce(
      (acc: { reportGroupTitle: string; kpis: Kpi[] }[], group) => {
        const filteredKpis = group.kpis.filter((kpi) =>
          kpi.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (filteredKpis.length > 0) {
          acc.push({
            reportGroupTitle: group.reportGroupTitle,
            kpis: filteredKpis,
          });
        }

        return acc;
      },
      []
    );

    setSearchResults(results);
  };

  const highlightText = (text: string, highlight: string) => {
    /* istanbul ignore next if */
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      // eslint-disable-next-line react/no-array-index-key
      regex.test(part) ? <Styled.HighlightText key={index}>{part}</Styled.HighlightText> : part
    );
  };

  const handleToggleCard = (index: string) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const totalResults = searchResults.reduce((total, group) => total + group.kpis.length, 0);

  return (
    <Styled.Wrapper>
      <SearchField.Root
        placeholder={formatMessage({ id: 'FAB.SEARCH' })}
        value={searchKpi}
        onChange={handleSearchKpi}
        onClear={handleClearKpi}
      />

      {searchKpi && (
        <Paragraph size="basis" css={{ opacity: '0.56' }}>
          {formatPlural({
            value: totalResults,
            singular: 'result',
            plural: 'results',
          })}{' '}
          for {`"${searchKpi}"`}
        </Paragraph>
      )}

      <Styled.Container>
        {searchResults.length > 0 &&
          searchResults.map((group, groupIndex) => (
            <Styled.KpiGroup key={group.reportGroupTitle}>
              <Heading size="H4" css={{ marginBottom: '16px' }}>
                {group.reportGroupTitle}
              </Heading>
              {group.kpis.map((kpi, kpiIndex) => (
                <div key={kpi.name}>
                  <ExpandableCard
                    title={highlightText(kpi.name, searchKpi)}
                    isExpanded={expandedIndex === `${groupIndex}-${kpiIndex}`}
                    onToggle={() => handleToggleCard(`${groupIndex}-${kpiIndex}`)}
                  >
                    <Styled.KpiItem>
                      <Styled.Paragraph size="small">
                        <strong>Description:</strong> {kpi.description}
                      </Styled.Paragraph>

                      <Styled.Paragraph size="small">
                        <strong>Methodology:</strong> {kpi.methodology}
                      </Styled.Paragraph>
                    </Styled.KpiItem>
                  </ExpandableCard>
                  <Divider orientation="horizontal" />
                </div>
              ))}
            </Styled.KpiGroup>
          ))}
      </Styled.Container>
    </Styled.Wrapper>
  );
}
