import { MenuItem } from 'Domain/NewMenuItem';

type PageInfo = {
  page_label: string;
  page_name: string;
  occurence_id?: string;
};

type PageConfig = {
  [key: string]: PageInfo;
};

const baseConfig: PageConfig = {
  MainPage: {
    page_label: 'Main Page',
    page_name: 'MAIN_PAGE',
  },
  ReportsList: {
    page_label: 'Report List',
    page_name: 'REPORT_LIST',
  },
  WorkspacesList: {
    page_label: 'Workspace List',
    page_name: 'WORKSPACE_LIST',
  },
  EmbedPage: {
    page_label: 'Reports',
    page_name: 'REPORTS',
  },
};

const changeButtonModalClick: PageConfig = {
  MainPage: {
    ...baseConfig.MainPage,
    occurence_id: '2.4',
  },
  ReportsList: {
    ...baseConfig.ReportsList,
    occurence_id: '2.7',
  },
  WorkspacesList: {
    ...baseConfig.WorkspacesList,
    occurence_id: '2.10',
  },
  EmbedPage: {
    ...baseConfig.EmbedPage,
    occurence_id: '2.15',
  },
};

const sidebarClick: PageConfig = {
  MainPage: {
    ...baseConfig.MainPage,
    occurence_id: '2.5',
  },
  ReportsList: {
    ...baseConfig.ReportsList,
    occurence_id: '2.8',
  },
  WorkspacesList: {
    ...baseConfig.WorkspacesList,
    occurence_id: '2.11',
  },
  EmbedPage: {
    ...baseConfig.EmbedPage,
    occurence_id: '2.13',
  },
};

const FABClick: PageConfig = {
  MainPage: {
    ...baseConfig.MainPage,
    page_label: 'Main Page',
    page_name: 'MAIN_PAGE',
    occurence_id: '2.19',
  },
  ReportsList: {
    ...baseConfig.ReportsList,
    page_label: 'Report List',
    page_name: 'REPORT_LIST',
    occurence_id: '2.20',
  },
  WorkspacesList: {
    ...baseConfig.WorkspacesList,
    page_label: 'Workspace List',
    page_name: 'WORKSPACE_LIST',
    occurence_id: '2.21',
  },
  EmbedPage: {
    ...baseConfig.EmbedPage,
    page_name: 'REPORTS',
    occurence_id: '2.22',
  },
};

export function byChangeButtonModalClick(page: string): PageInfo | undefined {
  return changeButtonModalClick[page];
}

export function bySidebarClick(page: string): PageInfo | undefined {
  return sidebarClick[page];
}

export function byFABClick(page: string): PageInfo | undefined {
  return FABClick[page];
}

export function getOriginInfo(
  url: string,
  reportsMenuItems?: MenuItem[]
): { themeName: string; themetrackingId: string; reportName: string; reportTrackingId: string } {
  const [, , , themeSlug, reportSlug] = url.split('/');

  const originTheme = reportsMenuItems?.find((item) => item.slug === themeSlug);
  const originReport = originTheme?.reportTabs?.find((item) => item.slug === reportSlug);

  return {
    themeName: originTheme?.title['en-US'] || '',
    themetrackingId: originTheme?.trackingId || '',
    reportName: originReport?.title['en-US'] || '',
    reportTrackingId: originReport?.trackingId || '',
  };
}
