import MessageMap from 'I18n/i18n';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },

  SELECTED_LANGUAGE: 'en-US',

  // NEED COPYDECK
  MAIN_PAGE: {
    TITLE: 'Analytics',
    BTGUIDE_SECTION: {
      TITLE: 'BT Guide',
      DESCRIPTION: 'Click here and access a step-by-step guide to maximize value with BEES.',
      MESSAGE: 'A step-by-step guide to maximize value with BEES.',
      LINK: 'Go to Business transformation guide',
    },
    REPORT_SECTION: {
      TITLE: 'Reports',
      SUBTITLE: 'Explore detailed reports on different themes.',
      BUTTON: 'Show all reports',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Available views',
      DESCRIPTION: 'Access different sets of data available to you.',
      BUTTON: 'Show all views',
    },
    EMPTY_STATE: {
      TITLE: 'Oops',
      DESCRIPTION: 'Apparently there is no workspace created or available for you.',
    },
    BIG_NUMBERS_SECTION: {
      TITLE: 'Month-to-date highlights',
      SUBTITLE: 'BEES Customer KPIs at a glance.',
    },
    DATASET_SECTION: {
      TITLE: 'Datasets',
      SUBTITLE: 'Download the data associated with each thematic report.',
    },
  },

  // NEED COPYDECK
  CARD_SECTION: {
    EMPTY_MESSAGE: 'Oops, sorry! No results found.',
  },

  WORKSPACES_PAGE: {
    TITLE: 'Available views',
    SEARCHFIELD: {
      PLACEHOLDER: 'Search by user type',
    },
  },

  HEADER: {
    ANALYTICS_BUTTON: 'Analytics admin',
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Change',
    NO_SEGMENT: 'No segment selected',
    VIEW: 'Current view:',
  },

  CHANGE_WORKSPACE_MODAL: {
    TITLE: 'Change view',
    DESCRIPTION: 'Select the options to change view.',
    WORKSPACE: {
      LABEL: 'User type',
      PLACEHOLDER: 'Select',
    },
    SEGMENT: {
      LABEL: 'View',
      PLACEHOLDER: 'Select',
    },
    COUNTRY: {
      LABEL: 'Country',
      PLACEHOLDER: 'Select',
    },
    SERVICE_MODEL: {
      LABEL: 'Business model',
      PLACEHOLDER: 'Select',
    },
    VENDOR: {
      LABEL: 'Company',
      PLACEHOLDER: 'Select',
      LOADING: 'Loading...',
    },
    CONFIRM_BUTTON: 'Change',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'view',
    PLURAL_TEXT: 'views',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
  },

  REPORTS_LIST_PAGE: {
    TITLE: 'Reports',
    SEARCH: {
      PLACEHOLDER: 'Search by theme or report name',
    },
    NOT_FOUND_STATE: {
      TITLE: 'No results found',
      TEXT: 'Check your spelling or try using different search terms.',
    },
    TOAST: {
      FIRST_PART: "You're now in the",
      LAST_PART: 'view',
    },
    REPORTS_QUANTITY: ' reports',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Data and analytics',
    REPORTS_PAGE_VENDOR: 'Vendor',
    CONTENT_REPORTS_TAB_VIEW:
      'Measure your performance across all delivered orders within the selected timeframe.',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Global overview',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Something went wrong',
    CONTAINER_ERROR_LABEL: "Sorry, we're having technical issues. Please try again.",
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Download CSV file',
    DESCRIPTION: 'You are creating a report with information from',
    YEAR_LABEL: 'Year',
    MONTH_LABEL: 'Month',
    SELLER_LABEL: 'Seller',
    NO_INFO_AVAILABLE:
      "There's no information for the selected period. Please try a different date.",
    CANCEL: 'Cancel',
    DOWNLOAD: 'Download',
    SELECT: 'Select',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Export data',
  },

  HELP_CENTER_MODAL: {
    TITLE: 'How to access a CSV file',
    DESCRIPTION:
      'Not familiar with CSV files? Check a step-by-step guide on how to use a CSV file.',
    LINK: 'Show guide to CSV files',
    PROCEED_BUTTON: 'Proceed to download',
  },

  GET_STARTED: {
    LOADING: 'Loading',
    TITLE: 'Get started',
    PARAGRAPH: 'Select a vendor to see their data and insights.',
    NEXT: 'Next',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Change',
    TITLE: 'You are viewing',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Open',
    CLEAR: 'Clear',
  },

  MONTH: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },

  COMMON: {
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
  },
  LOADING: {
    TITLE: 'Loading',
    PARAGRAPH: 'We are preparing the page for you.',
  },
  SELECT_VENDOR: {
    TITLE: 'Select a vendor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Access denied!',
    ERROR_DESCRIPTION: 'It looks like you don’t have permission to access this page.',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    PREPARING_DOWNLOAD: 'Preparing CSV file for download.',
    SUCCESS_DOWNLOAD: 'Download completed successfully.',
  },

  CONTACT_US: {
    TOAST_SUCCESS: 'Request successfully sent! Our team will contact you shortly.',
    TOAST_ERROR: 'Something went wrong. Please try again.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brazil',
    CL: 'Chile',
    CO: 'Colombia',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ID: 'Indonesia',
    MX: 'Mexico',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Philippines',
    PY: 'Paraguay',
    ZA: 'South Africa',
    CH: 'Switzerland',
    BE: 'Belgium',
    IC: 'Canary Island',
    ES: 'Spain',
    DE: 'Germany',
    GB: 'United Kingdom',
    NL: 'Netherlands',
    GLOBAL_OVERVIEW: 'Global overview',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Select a country',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
    '3P': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Select a business model',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Select a vendor',
    LOADING: 'Loading...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Fetching the data...',
      BODY: 'Step 1 out of 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Loading report...',
      BODY: 'Step 2 out of 3',
    },

    LOADING_DATA: {
      TITLE: 'We are almost there',
      BODY: 'Step 3 out of 3',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
    MAIN_PAGE: 'Main page',
  },

  TOAST: {
    SUCCESS: 'Success! Your changes have been saved',
    ERROR: 'Something went wrong. Please try again later',
    ID_ALREADY_EXISTS: 'The id already exists. Please choose another one.',
  },

  SWITCH_LOADING: {
    TITLE: 'Switching views',
    ENTERING: 'You are entering',
    PARAGRAPH: 'Wait while the view loads.',
  },

  BEES_ASSISTANT: {
    DESCRIPTION: "Need help with BEES Analytics? We're here for you.",
  },

  BIG_NUMBERS: {
    BUTTON: 'Go to detailed report',
  },

  DATASET_TABLE: {
    SEARCH: 'Search by dataset name',
    EMPTY_MESSAGE: 'No results found',
  },
  FAB: {
    TOOLTIP_MESSAGE_1: 'Need help to understand a KPI?',
    TOOLTIP_MESSAGE_2: 'Check our documentation here.',
    TITLE: 'Understanding our KPIs',
    DESCRIPTION:
      'Enter a KPI name into the search bar to show its definition and how it is calculated.',
    SEARCH: 'Search by KPI or keyword',
  },
};

export default enUS;
