/* eslint-disable max-params */
import { Workspace } from 'Domain/Workspaces';

export function moveItemToFirstPositionAndSort(
  id: string,
  allWorkspaces: Workspace[],
  selectedLanguage: string
): Workspace[] {
  const itemWithId = allWorkspaces.find((workspace) => workspace.id === id);

  if (!itemWithId) {
    return allWorkspaces.sort((a: Workspace, b: Workspace) =>
      a.title[selectedLanguage].localeCompare(b.title[selectedLanguage])
    );
  }

  const remainingItems = allWorkspaces.filter((workspace) => workspace.id !== id);

  remainingItems.sort((a: Workspace, b: Workspace) =>
    a.title[selectedLanguage].localeCompare(b.title[selectedLanguage])
  );

  return [itemWithId, ...remainingItems];
}
