export interface HideSpacesParams {
  isMobile: boolean;
  isTablet: boolean;
  isDesktopSmall: boolean;
  data: any[];
  hasBtGuide: boolean;
}

export const hideSpaces = ({
  isMobile,
  isTablet,
  isDesktopSmall,
  data,
  hasBtGuide,
}: HideSpacesParams): boolean => {
  return (
    shouldHideForMobile(isMobile, data) ||
    shouldHideForTablet(isMobile, isTablet, data) ||
    shouldHideForDesktopSmall(isTablet, isDesktopSmall, data) ||
    shouldHideForOthers(isDesktopSmall, data, hasBtGuide)
  );
};

const shouldHideForMobile = (isMobile: boolean, data: any[]): boolean => {
  return isMobile && data.length === 1;
};

const shouldHideForTablet = (isMobile: boolean, isTablet: boolean, data: any[]): boolean => {
  return !isMobile && isTablet && data.length <= 2;
};

const shouldHideForDesktopSmall = (isTablet: boolean, isDesktopSmall: boolean, data: any[]): boolean => {
  return !isTablet && isDesktopSmall && data.length <= 3;
};

const shouldHideForOthers = (isDesktopSmall: boolean, data: any[], hasBtGuide: boolean): boolean => {
  return !isDesktopSmall && data.length <= (hasBtGuide ? 3 : 4);
};
