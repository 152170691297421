/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import useTrackingService from 'Hooks/useTrackingService';

const useSegment = () => {
  const segmentAnalytics = useTrackingService().getAnalyticsTypewriter();

  const {
    csvDownloadRequested,
    partnerSelected,
    tabInteraction,
    menuInteraction,
    pageViewed,
    csvDownloadRequestSuccessful,
    csvDownloadRequestFailed,
    partnerReportsViewed,
    partnerInsightsAppLoaded,
    csvDownloadStarted,
    csvDownloadCanceled,
    filtersInteraction,
    searchInteraction,
    initRenderingProcess,
    changePartnerButtonClicked,
    dataLoadedRenderingProcess,
    reportRenderedRenderingProcess,
    interactionRenderedRenderingProcess,
    rederingStartedRenderingProcess,
    linkOutOptionClicked,
    elementClicked,
    searchApplied,
    fileProcessed,
  } = segmentAnalytics;

  return {
    csvDownloadRequested,
    partnerSelected,
    tabInteraction,
    menuInteraction,
    pageViewed,
    csvDownloadRequestSuccessful,
    csvDownloadRequestFailed,
    partnerReportsViewed,
    partnerInsightsAppLoaded,
    csvDownloadStarted,
    csvDownloadCanceled,
    filtersInteraction,
    searchInteraction,
    changePartnerButtonClicked,
    dataLoadedRenderingProcess,
    initRenderingProcess,
    reportRenderedRenderingProcess,
    interactionRenderedRenderingProcess,
    rederingStartedRenderingProcess,
    linkOutOptionClicked,
    elementClicked,
    searchApplied,
    fileProcessed,
  };
};

export default useSegment;
