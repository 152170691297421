import { Card as HexaCard, TextLink } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Card = styled(HexaCard, {
  width: '100%',
  padding: '24px',
  gap: '8px',
  paddingBottom: '24px',
});

const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const LinkContainer = styled(TextLink, {
  width: 'fit-content',
  color: '#0F7FFA!important',
  paddingTop: '8px',
  marginTop: 'auto',
});

const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '160px',
});

export const Styled = {
  Card,
  TitleContainer,
  LinkContainer,
  CenterContainer,
};
