import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { MenuItensResponse } from 'Domain/NewMenuItem';

export const getReportsByWorkspace = async (
  workspaceId: string,
  country: string,
  permissionId?: string,
  vendorId?: string,
  role?: string
  // eslint-disable-next-line max-params
): Promise<MenuItensResponse> => {
  const baseUrl = getAPIBaseURL(APIEndpoint.GET_REPORT_BY_WORKSPACE_ID, country);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const response = await Api.get({
    url: `config/reports/${workspaceId}`,
    baseURL: baseUrl,
    headers: {
      country,
    },
    params: {
      permissionId,
      vendorId,
      role,
    },
  });

  return response.data;
};
