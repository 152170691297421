import { Paragraph } from '@hexa-ui/components';
import { ChevronDown, ChevronUp } from '@hexa-ui/icons';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import { useCheckCurrentPage } from 'Hooks/useCheckCurrentPage/useCheckCurrentPage';
import { useReports } from 'Hooks/useReports/useReports';
import React, { useEffect, useRef, useState } from 'react';
import { Styled } from './ExpandableCard.style';
import { ExpandableCardProps } from './ExpandableCard.types';

function ExpandableCard({
  title,
  children,
  isExpanded,
  onToggle,
  kpis,
  themeSelected,
  index,
}: ExpandableCardProps): React.ReactElement {
  const [maxHeight, setMaxHeight] = useState<string>('0px');
  const contentRef = useRef<HTMLDivElement>(null);
  const { triggerElementClicked } = useElementClickedEvent();
  const { reportBySlug } = useReports();
  const themeTrackingId = themeSelected?.trackingId;
  const themeTitle = themeSelected?.title['en-US'];

  useEffect(() => {
    setMaxHeight(isExpanded ? `${contentRef?.current?.scrollHeight}px` : '0px');
  }, [isExpanded]);

  const currentPage = useCheckCurrentPage();
  const isEmbedPage = currentPage === 'EmbedPage';

  const getKPIName = () => {
    kpis.forEach((kpi) => kpi.name);
    return kpis[0].name;
  };

  const triggerElementClickedEvent = () => {
    triggerElementClicked({
      is_core_event: false,
      page_label: 'BEES assistant',
      page_name: 'FLOATING_ACTION_BUTTON',
      element_location_type: 'MODAL',
      origin_theme_label: isEmbedPage ? themeTitle : null,
      origin_theme_tracking_id: isEmbedPage ? themeTrackingId : null,
      origin_report_label: isEmbedPage ? reportBySlug?.title['en-US'] : null,
      origin_report_tracking_id: isEmbedPage ? reportBySlug?.trackingId : null,
      element_location_name: themeTitle,
      element_location_label: themeTitle,
      element_label: getKPIName(),
      element_name: 'KPI_SEARCH_RESULT',
      element_type: 'DROPDOWN',
      element_position: index + 1,
      occurence_id: '2.23',
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.Item onClick={onToggle} data-testid="expandable-card-title">
        <Paragraph weight="medium">{title}</Paragraph>
        {isExpanded ? (
          <ChevronUp onClick={triggerElementClickedEvent} />
        ) : (
          <ChevronDown onClick={triggerElementClickedEvent} />
        )}
      </Styled.Item>
      <div
        ref={contentRef}
        style={{
          maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease',
        }}
      >
        <Styled.Content>{children}</Styled.Content>
      </div>
    </Styled.Wrapper>
  );
}

export default ExpandableCard;
