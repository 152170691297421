import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DatasetsResponse } from 'Domain/DatasetTable.types';
import { getDatasets, getDatasetsProps } from './getDatasets';

export const useGetDatasets = ({
  country,
  permissionId,
  workspaceId,
}: getDatasetsProps): UseQueryResult<DatasetsResponse> =>
  useQuery({
    queryKey: ['getDatasets', country, permissionId, workspaceId],
    queryFn: () => getDatasets({ country, permissionId, workspaceId }),
  });
