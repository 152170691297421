import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentProperties } from '../useSegmentProperties/useSegmentProperties';
import { FileProcessedEventProps, FileProcessedEventReturn } from './useFileProcessedEvent.types';

export default function useFileProcessed(): FileProcessedEventReturn {
  const { fileProcessed } = useSegment();

  const {
    app_display_name,
    app_id,
    page_url,
    origin_workspace_label,
    origin_workspace_tracking_id,
    user_affiliation,
    user_country,
    user_email,
    user_id,
    vendor_country,
    vendor_display_name,
    vendor_id,
    vendor_service_model,
    vendor_tier_name,
  } = useSegmentProperties();

  const triggerFileProcessed = ({
    dataset_name,
    file_format,
    file_process_type,
    is_core_event,
    occurence_id,
    origin_report_label,
    origin_report_tracking_id,
    origin_theme_label,
    origin_theme_tracking_id,
    page_label,
    page_name,
    processing_duration,
    processing_status,
  }: FileProcessedEventProps) => {
    fileProcessed({
      app_display_name,
      app_id,
      dataset_name,
      file_format,
      file_process_type,
      is_core_event,
      occurence_id,
      origin_report_label,
      origin_report_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_workspace_label,
      origin_workspace_tracking_id,
      page_label,
      page_name,
      page_url,
      processing_duration,
      processing_status,
      user_affiliation,
      user_country,
      user_email,
      user_id,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
    });
  };

  return { triggerFileProcessed };
}
