import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';

interface BTGuideParams {
  vendorId?: string;
  country?: string;
}

const getBTGuideService = async (params: BTGuideParams): Promise<any> => {
  if (!params.vendorId || !params.country) {
    return false;
  }

  const baseURL = getAPIBaseURL(APIEndpoint.GET_ACTIVATION_VENDORS, params.country);

  const response = await Api.get({
    url: `/activation/vendors`,
    headers: {
      country: params.country,
    },
    baseURL,
  });

  const vendor = response.data.find(
    (vendor: any) => vendor.id === params.vendorId && vendor.btGuideEnabled
  );

  if (!vendor) {
    return false;
  }
  const newBaseURL = getAPIBaseURL(APIEndpoint.GET_BTGUIDE_BY_ID, params.country);

  const newResponse = await Api.get({
    url: `/bt-guide/${vendor.id}`,
    headers: {
      country: params.country,
    },
    baseURL: newBaseURL,
  });

  return newResponse.data;
};

export default getBTGuideService;
