import { Select } from '@hexa-ui/components';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { PermissionRuleType, Workspace, WorkspaceRLSSchemaValue } from 'Domain/Workspaces';
import { useStore } from 'effector-react';
import useUserGroups from 'Hooks/useUserGroups/useUserGroups';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import LoggedUserStore from 'Store/LoggedUser/LoggedUserStore';
import { isManagerUser } from 'Utils/isManagerUser/isManagerUser';
import { Styled } from './ChangeWorkspaceModalForm.style';
import ServiceModelSelector from './ServiceModelSelector/ServiceModelSelector';
import VendorSelector from './VendorSelector/VendorSelector';

const vendorSelectionNecessary = (
  segmentSelected: any,
  isVendorSegmentSelected: any,
  isManufacturerSegmentSelected: any,
  activeWorkspace: { rowLevelSecurity: { schema: string | any[] } } | undefined
  // eslint-disable-next-line max-params
) =>
  segmentSelected &&
  !isVendorSegmentSelected &&
  !isManufacturerSegmentSelected &&
  activeWorkspace?.rowLevelSecurity.schema.includes(WorkspaceRLSSchemaValue.VENDOR_ID);

export function ChangeWorkspaceModalForm(): React.ReactElement {
  const { formatMessage } = useIntl();
  const { vendors: userVendors } = useStore(LoggedUserStore);
  const userGroups = useUserGroups();

  const SELECTED_LANGUAGE = formatMessage({ id: 'SELECTED_LANGUAGE' });

  const { tempWorkspaceId } = useWorkspaceContext();

  const { control, setValue, trigger } = useFormContext();

  const { data } = useGetWorkspaces();

  const allWorkspaces = data?.workspaces?.sort((a: Workspace, b: Workspace) =>
    a.title[SELECTED_LANGUAGE].localeCompare(b.title[SELECTED_LANGUAGE])
  );

  const workspaceSelected = useWatch({ name: 'workspaceId' });
  const segmentSelected = useWatch({ name: 'segmentId' });
  const countrySelected = useWatch({ name: 'country' });
  const vendorIdSelected = useWatch({ name: 'vendorId' });
  const vendorNameSelected = useWatch({ name: 'vendorName' });
  const fullVendorSelected = `${vendorIdSelected}|${vendorNameSelected}`;

  const getWorkspaceById = allWorkspaces?.find((data) => data.id === tempWorkspaceId);

  const [serviceModel, setServiceModel] = useState<string>();
  const activeWorkspace = allWorkspaces?.find((workspace) => workspace.id === workspaceSelected);
  const activeSegment = activeWorkspace?.permissions.find(
    (segment) => segment.id === segmentSelected
  );
  const isVendorSegmentSelected = activeSegment?.rule?.type === PermissionRuleType.VENDOR_ID;
  const isManufacturerSegmentSelected =
    activeSegment?.rule?.type === PermissionRuleType.MANUFACTURER_ID;
  const isCountrySegmentSelected = activeSegment?.rule?.type === PermissionRuleType.COUNTRY;
  const isBusinessModelSegmentSelected =
    activeSegment?.rule?.type === PermissionRuleType.BUSINESS_MODEL;

  const segmentsAllowed = activeWorkspace && activeWorkspace?.permissions?.length > 0;

  const isManager = isManagerUser(userGroups);

  const isVendorSelectionNecessary = vendorSelectionNecessary(
    segmentSelected,
    isVendorSegmentSelected,
    isManufacturerSegmentSelected,
    activeWorkspace
  );

  const showManagerVendorSelection = isVendorSelectionNecessary && isManager;

  const segmentMatchingUserVendors = useMemo(
    () =>
      userVendors?.filter((vendor) => {
        if (isCountrySegmentSelected) return vendor.country === countrySelected;
        if (isBusinessModelSegmentSelected) return vendor.serviceModel === serviceModel;
        return false;
      }),
    [
      userVendors,
      isCountrySegmentSelected,
      isBusinessModelSegmentSelected,
      countrySelected,
      serviceModel,
    ]
  );

  const showPartnerUserVendorSelection =
    !isManager && isVendorSelectionNecessary && segmentMatchingUserVendors?.length > 0;

  const showVendorSelection = showManagerVendorSelection || showPartnerUserVendorSelection;

  useEffect(() => {
    if (tempWorkspaceId) {
      setValue('workspaceId', getWorkspaceById?.id);
      trigger('workspaceId');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempWorkspaceId]);

  useEffect(() => {
    setValue('segmentId', '');
    setValue('vendorId', '');
    setValue('vendorName', '');
    setServiceModel(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceSelected]);

  useEffect(() => {
    setValue('country', activeSegment?.country);

    if (isVendorSegmentSelected || isManufacturerSegmentSelected) {
      setValue('vendorId', activeSegment?.rule?.value);
      setValue('vendorName', activeSegment?.name);
    } else {
      setValue('vendorId', '');
      setValue('vendorName', '');
    }

    if (isBusinessModelSegmentSelected) {
      setServiceModel(activeSegment?.rule?.value);
    } else {
      setServiceModel(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isVendorSegmentSelected,
    isManufacturerSegmentSelected,
    isBusinessModelSegmentSelected,
    activeSegment,
  ]);

  useEffect(() => {
    if (showPartnerUserVendorSelection && segmentMatchingUserVendors.length === 1) {
      setValue('vendorId', segmentMatchingUserVendors[0].id);
      setValue('vendorName', segmentMatchingUserVendors[0].displayName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPartnerUserVendorSelection, segmentMatchingUserVendors]);

  const segmentsOptions = activeWorkspace?.permissions?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const renderWorkspaces = (allWorkspaces: Workspace[], SELECTED_LANGUAGE: string) => {
    if (!allWorkspaces?.length) {
      return null;
    }

    return (
      <>
        {allWorkspaces.map((option) => (
          <Select.Option key={option.id} value={option.id}>
            {option.title[SELECTED_LANGUAGE]}
          </Select.Option>
        ))}
      </>
    );
  };

  return (
    <Styled.Container>
      <div>
        <Controller
          name="workspaceId"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange } }) => (
            <Select.Root
              data-testid="select-workspace"
              label={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.WORKSPACE.LABEL' })}
              placeholder={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.WORKSPACE.PLACEHOLDER' })}
              onChange={onChange}
              defaultValue={getWorkspaceById?.id}
            >
              {allWorkspaces && renderWorkspaces(allWorkspaces, SELECTED_LANGUAGE)}
            </Select.Root>
          )}
        />
      </div>

      {segmentsAllowed && (
        <>
          <div key={workspaceSelected}>
            <Controller
              name="segmentId"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange } }) => (
                <Select.Root
                  data-testid="select-segment"
                  label={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.SEGMENT.LABEL' })}
                  placeholder={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.SEGMENT.PLACEHOLDER' })}
                  onChange={(e) => {
                    setValue('vendorId', '');
                    setValue('vendorName', '');
                    onChange(e);
                  }}
                  disabled={!workspaceSelected}
                >
                  {segmentsOptions?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select.Root>
              )}
            />
          </div>

          {showVendorSelection && (
            <Controller
              name="vendorId"
              control={control}
              rules={{
                required: showVendorSelection,
              }}
              render={({ field: { onChange } }) => (
                <>
                  {isManager && isCountrySegmentSelected && (
                    <div key={serviceModel}>
                      <ServiceModelSelector
                        onChange={
                          /* istanbul ignore next */ (e) => {
                            onChange('');
                            setValue('vendorName', '');
                            setServiceModel(e);
                          }
                        }
                        selectedValue={serviceModel}
                      />
                    </div>
                  )}
                  <div key={fullVendorSelected}>
                    {!isManager && (
                      <Select.Root
                        data-testid="workspace-vendor-select"
                        label={formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.VENDOR.LABEL' })}
                        placeholder={formatMessage({
                          id: 'CHANGE_WORKSPACE_MODAL.VENDOR.PLACEHOLDER',
                        })}
                        onChange={
                          /* istanbul ignore next */ (newValue) => {
                            const [vendorId, vendorName] = newValue.split('|');
                            onChange(vendorId);
                            setValue('vendorName', vendorName);
                          }
                        }
                        value={fullVendorSelected !== '|' ? fullVendorSelected : undefined}
                        disabled={segmentMatchingUserVendors?.length < 2}
                      >
                        {segmentMatchingUserVendors?.map((value, index) => (
                          <Select.Option
                            key={value.id}
                            value={`${value.id}|${value.displayName}`}
                            data-testid={`workspace-vendor-select-option-${index}`}
                          >
                            {value.displayName}
                          </Select.Option>
                        ))}
                      </Select.Root>
                    )}
                    {isManager && (
                      <VendorSelector
                        onChange={
                          /* istanbul ignore next */ (newValue) => {
                            const [vendorId, vendorName] = newValue.split('|');
                            onChange(vendorId);
                            setValue('vendorName', vendorName);
                          }
                        }
                        selectedValue={
                          fullVendorSelected !== '|'
                            ? /* istanbul ignore next */ fullVendorSelected
                            : undefined
                        }
                        country={countrySelected}
                        serviceModel={serviceModel}
                      />
                    )}
                  </div>
                </>
              )}
            />
          )}
        </>
      )}
    </Styled.Container>
  );
}
