import CSVDownloadButton from 'Components/CSVDownloadButton/CSVDownloadButton';
import CSVDownloadModal from 'Components/CSVDownloadModal/CSVDownloadModal';
import { TrackingContext } from 'Context/TrackingContext';
import { CSVDownload } from 'Domain/NewMenuItem';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import { useHandlerControllers } from 'Hooks/useHandlerControllers/useHandlerControllers';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useCSVDownload } from '../../../hooks/useCSVDownload/useCsvDownload';
import { useHelpCenter } from '../../../hooks/useHelpCenter/useHelpCenter';
import { Styled } from './ContentReportView.style';

interface ContentReportViewProps {
  csvDownload: CSVDownload[];
}

/* istanbul ignore next */
export function ContentReportView({
  csvDownload,
}: Readonly<ContentReportViewProps>): React.ReactElement {
  const { setIsOpen, isOpen } = useHandlerControllers();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { downloadCSV } = useCSVDownload({
    originDownload: 'REPORTS',
    csvDownload,
    setError,
    setIsLoading,
  });

  const { onOpenModal, openCSVDownloadModal, closeCSVDownloadModal, indexCSVDownload } =
    useHelpCenter(csvDownload);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    closeCSVDownloadModal();
  }, [closeCSVDownloadModal, setIsOpen]);

  useEffect(() => {
    if (openCSVDownloadModal) {
      setIsOpen(openCSVDownloadModal);
    }
  }, [openCSVDownloadModal, isOpen, setIsOpen]);

  const { triggerElementClicked } = useElementClickedEvent();
  const trackingContext = useContext(TrackingContext);

  const handleDownload = (
    period: { year: number; month: number },
    sellerId?: string | undefined
  ) => {
    triggerElementClicked({
      is_core_event: true,
      page_label: `${trackingContext?.themeName} - ${trackingContext?.reportName}`,
      page_name: 'REPORTS',
      element_location_type: 'MODAL',
      element_location_name: 'DOWNLOAD_CSV',
      element_location_label: 'Export CSV file',
      element_label: 'Export',
      element_name: 'DOWNLOAD_BUTTON',
      element_type: 'BUTTON',
      origin_theme_label: trackingContext?.themeName,
      origin_theme_tracking_id: trackingContext?.themeTrackingId,
      origin_report_label: trackingContext?.reportName,
      origin_report_tracking_id: trackingContext?.reportTrackingId,
      occurence_id: '2.12',
    });
    downloadCSV(period, sellerId);
  };

  return (
    <Styled.Wrapper>
      <CSVDownloadButton onClick={onOpenModal} />
      <CSVDownloadModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        onConfirm={handleDownload}
        indexCSVDownload={indexCSVDownload}
        csvDownload={csvDownload}
        error={error}
        setError={setError}
        isLoading={isLoading}
      />
    </Styled.Wrapper>
  );
}
