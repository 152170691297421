import { Divider, Heading, LoadingDots, Paragraph, SearchField } from '@hexa-ui/components';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { Kpi } from 'Domain/Kpis';
import useDebounce from 'Hooks/useDebounce/useDebounce';
import { useReports } from 'Hooks/useReports/useReports';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetKPIs } from 'Services/GetKPIs/useGetkPIs';
import { formatPlural } from 'Utils/formatPlural/formatPlural';
import ExpandableCard from '../ExpandableCard/ExpandableCard';
import { Styled } from './FabContent.style';

interface FabContentProps {
  contentRef: React.RefObject<HTMLDivElement>;
}

export function FabContent({ contentRef }: FabContentProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { preferredLanguage } = usePreferredLanguageV2();

  const { workspaceData: selectedWorkspace } = useWorkspaceContext();

  const { reportsIds, sortedMenuItems, themeSelected } = useReports();

  const [searchKpi, setSearchKpi] = useState('');
  const [expandedIndex, setExpandedIndex] = useState<string | null>(null);
  const [groupedKPIs, setGroupedKPIs] = useState<any[]>([]);

  const DEBOUNCE_DELAY = 1000;
  const debouncedSearchKPI = useDebounce(searchKpi, DEBOUNCE_DELAY);

  const handleClearKpi = () => {
    setSearchKpi('');
    setGroupedKPIs([]);
    setExpandedIndex(null);
  };

  const handleSearchKpi = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchKpi(value);
    setExpandedIndex(null);
  }, []);

  const { data, isLoading } = useGetKPIs({
    country: selectedWorkspace?.segment?.country,
    buzzword: debouncedSearchKPI,
    lang: preferredLanguage ?? 'en-US',
    reportIds: reportsIds,
  });

  /* istanbul ignore next */
  const reportGroupName = (reportId: string): string => {
    const menuItem = sortedMenuItems?.find((menuItem) =>
      menuItem?.reportTabs?.some((tab) => tab.id === reportId)
    );
    return menuItem?.title[preferredLanguage ?? 'en-US'] || ' ';
  };

  const transformAndGroupReports = (data: {
    kpis: { reportId: string; name: string; description: string; methodology: string }[];
  }) => {
    const kpiMap = new Map<
      string,
      {
        reportGroupTitle: string;
        kpis: { name: string; description: string; methodology: string }[];
      }
    >();

    data?.kpis?.forEach(({ reportId, name, description, methodology }) => {
      if (!kpiMap.has(reportId)) {
        const reportGroupTitle = reportGroupName(reportId);
        kpiMap.set(reportId, { reportGroupTitle, kpis: [] });
      }
      kpiMap.get(reportId)!.kpis.push({ name, description, methodology });
    });

    const reportMap = new Map<string, any>();

    kpiMap.forEach((value) => {
      const { reportGroupTitle, kpis } = value;
      if (!reportMap.has(reportGroupTitle)) {
        reportMap.set(reportGroupTitle, { reportGroupTitle, kpis: [] });
      }
      reportMap.get(reportGroupTitle)!.kpis.push(...kpis);
    });

    const result: any[] = [];
    reportMap.forEach((report) => {
      result.push(report);
    });

    return result;
  };

  useEffect(() => {
    setGroupedKPIs(transformAndGroupReports(data));
  }, [data]);

  const ungroupedKpiNames = groupedKPIs.map((group) => group.kpis.map((kpi: Kpi) => kpi.name));
  const groupedKpiNames = ungroupedKpiNames.flat();

  const getKpiIndex = (kpiName: string) => groupedKpiNames.findIndex((kpi) => kpi === kpiName);

  const highlightText = (text: string, highlight: string) => {
    /* istanbul ignore next if */
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      // eslint-disable-next-line react/no-array-index-key
      regex.test(part) ? <Styled.HighlightText key={index}>{part}</Styled.HighlightText> : part
    );
  };

  const handleToggleCard = (index: string) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const totalResults = groupedKPIs.reduce((total, group) => total + group.kpis.length, 0);

  return (
    <Styled.Wrapper>
      <SearchField.Root
        placeholder={formatMessage({ id: 'FAB.SEARCH' })}
        value={searchKpi}
        onChange={handleSearchKpi}
        onClear={handleClearKpi}
      />

      {debouncedSearchKPI.length >= 2 && isLoading ? (
        <Styled.Loading data-testid="loading-dots">
          <LoadingDots />
        </Styled.Loading>
      ) : (
        debouncedSearchKPI.length >= 2 && (
          <>
            <Paragraph size="basis" css={{ opacity: '0.56' }}>
              {formatPlural({
                value: totalResults,
                singular: 'result',
                plural: 'results',
              })}{' '}
              for {`"${debouncedSearchKPI}"`}
            </Paragraph>
            <Styled.Container ref={contentRef}>
              {groupedKPIs.length > 0 &&
                groupedKPIs.map((group, groupIndex) => (
                  <Styled.KpiGroup key={group.reportGroupTitle}>
                    <Heading size="H4" css={{ marginBottom: '16px' }}>
                      {group.reportGroupTitle}
                    </Heading>
                    {group.kpis.map((kpi: Kpi, kpiIndex: number) => (
                      <div key={kpi.name}>
                        <ExpandableCard
                          title={highlightText(kpi.name, searchKpi)}
                          isExpanded={expandedIndex === `${groupIndex}-${kpiIndex}`}
                          onToggle={() => handleToggleCard(`${groupIndex}-${kpiIndex}`)}
                          kpis={[kpi]}
                          themeSelected={themeSelected}
                          index={getKpiIndex(kpi.name)}
                        >
                          <Styled.KpiItem>
                            <Styled.Paragraph size="small">
                              <strong>Description:</strong> {kpi.description}
                            </Styled.Paragraph>

                            <Styled.Paragraph size="small">
                              <strong>Methodology:</strong> {kpi.methodology}
                            </Styled.Paragraph>
                          </Styled.KpiItem>
                        </ExpandableCard>
                        <Divider orientation="horizontal" />
                      </div>
                    ))}
                  </Styled.KpiGroup>
                ))}
            </Styled.Container>
          </>
        )
      )}
    </Styled.Wrapper>
  );
}
