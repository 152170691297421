import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getBTGuideService from './getBTGuideService';

export const useBTGuideById = (vendorId?: string, country?: string): UseQueryResult<any> => {
  const result = useQuery({
    queryKey: ['btGuideById', vendorId, country],
    queryFn: () => getBTGuideService({ vendorId: vendorId as string, country: country as string }),
    refetchOnWindowFocus: false,
    refetchInterval: 1 * 60 * 60 * 1000,
    staleTime: 1,
  });

  return result;
};
