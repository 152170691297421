import useLoadingTimeEvent from 'Hooks/segment/useLoadingTimeEvent/useLoadingTimeEvent';
import { useCheckCurrentPage } from 'Hooks/useCheckCurrentPage/useCheckCurrentPage';
import { useMemo, useState } from 'react';
import {
  PowerBIEventHandler,
  PowerBIReportEvent,
  PowerBIReportStatus,
  UsePowerBIReportReturn,
} from './usePowerBi.types';
import usePowerBIReportEvents from './usePowerBiEvents';

export function usePowerBi(): UsePowerBIReportReturn {
  const [status, setStatus] = useState(PowerBIReportStatus.LOADING_EMBED_REPORT);
  const {
    triggerDataLoadedRendering,
    triggerReportRenderedProcess,
    triggerRenderingStartedRenderingProcess,
    triggerInteractionRenderedProcess,
  } = useLoadingTimeEvent();

  const { trackReportEvent } = usePowerBIReportEvents();
  const isLoading = ![PowerBIReportStatus.RENDERING_STARTED, PowerBIReportStatus.RENDERED].includes(
    status
  );
  const currentPage = useCheckCurrentPage();

  const eventHandlers = useMemo(
    () =>
      new Map<string, PowerBIEventHandler>([
        [
          'loaded',
          () => {
            setStatus(PowerBIReportStatus.LOADING_DATA);
            trackReportEvent(PowerBIReportEvent.DATA_LOADED);
            if (currentPage !== 'MainPage') {
              triggerDataLoadedRendering({
                is_core_event: false,
                page_name: 'REPORTS',
                occurence_id: 'A2',
              });
            }
          },
        ],
        [
          'renderingStarted',
          () => {
            setStatus(PowerBIReportStatus.RENDERING_STARTED);
            trackReportEvent(PowerBIReportEvent.RENDERING_STARTED);
            if (currentPage !== 'MainPage') {
              triggerRenderingStartedRenderingProcess({
                is_core_event: false,
                page_name: 'REPORTS',
                occurence_id: 'A3',
              });
            }
          },
        ],
        [
          'rendered',
          () => {
            setStatus(PowerBIReportStatus.RENDERED);
            trackReportEvent(PowerBIReportEvent.REPORT_RENDERED);
            if (currentPage !== 'MainPage') {
              triggerReportRenderedProcess({
                is_core_event: false,
                page_name: 'REPORTS',
                occurence_id: 'A4',
              });
            }
          },
        ],
        [
          'dataSelected',
          () => {
            triggerInteractionRenderedProcess({
              is_core_event: true,
              page_name: 'REPORTS',
              occurence_id: 'A5',
            });
            trackReportEvent(PowerBIReportEvent.INTERACTION);
          },
        ],
        [
          'buttonClicked',
          () => {
            triggerInteractionRenderedProcess({
              is_core_event: true,
              page_name: 'REPORTS',
              occurence_id: 'A5',
            });
            trackReportEvent(PowerBIReportEvent.INTERACTION);
          },
        ],
        [
          'visualClicked',
          () => {
            triggerInteractionRenderedProcess({
              is_core_event: true,
              page_name: 'REPORTS',
              occurence_id: 'A5',
            });
            trackReportEvent(PowerBIReportEvent.INTERACTION);
          },
        ],
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLoading,
      trackReportEvent,
      triggerDataLoadedRendering,
      triggerInteractionRenderedProcess,
      triggerRenderingStartedRenderingProcess,
      triggerReportRenderedProcess,
    ]
  );

  return { eventHandlers, status, isLoading, trackReportEvent, setStatus };
}
