import { Heading, Paragraph } from '@hexa-ui/components';
import { IconName } from 'Components/IconsMap/IconsMap';
import useElementClickedEvent from 'Hooks/segment/useElementClicked/useElementClicked';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import iconMap from 'Utils/iconMap/iconMap';
import Styled from './CardReports.style';
import { CardProps } from './CardReports.types';

export const GRAY_COLOR = 'rgba(20, 20, 20, 0.56)';

function CardReports({ item, workspaceName, segmentName, index }: CardProps): React.ReactElement {
  const history = useHistory();

  const {
    fullName,
    iconString,
    reportName,
    reportSlug,
    reportTrackingId,
    themeName,
    themeSlug,
    themeTrackingId,
  } = item;

  const renderIcon = useCallback((iconName: IconName) => {
    const IconComponent = iconMap[iconName];
    return IconComponent;
  }, []);

  const { triggerElementClicked } = useElementClickedEvent();

  const handleClick = () => {
    triggerElementClicked({
      is_core_event: true,
      page_label: 'Main Page',
      page_name: 'MAIN_PAGE',
      element_location_type: 'SECTION',
      element_location_name: 'REPORTS',
      element_location_label: 'Reports',
      element_name: 'REPORT_CARD',
      element_label: reportName,
      element_type: 'CARD',
      element_position: index + 1,
      destiny_theme_label: themeName,
      destiny_theme_tracking_id: themeTrackingId,
      destiny_report_label: reportName,
      destiny_report_tracking_id: reportTrackingId,
      occurence_id: '2.1',
    });

    history.push(`/reports/embed/${themeSlug}/${reportSlug}`, { from: window.location.href });
  };

  return (
    <Styled.CardContainer
      border="medium"
      data-testid="report-card"
      id="report-card"
      elevated="minimal"
      onClick={handleClick}
    >
      <Styled.Circle>{renderIcon(iconString)}</Styled.Circle>

      <Styled.CardContent>
        <Heading size="H4">{fullName}</Heading>
        <Paragraph size="xsmall" css={{ color: GRAY_COLOR }}>
          {workspaceName}
          {segmentName && ` - ${segmentName}`}
        </Paragraph>
      </Styled.CardContent>
    </Styled.CardContainer>
  );
}

export default CardReports;
