import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAnalyticsService } from 'admin-portal-shared-services';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import queryClient from 'Config/queryClient';
import { ClusterProvider } from 'Context/ClusterContext/ClusterContext';
import { SwitchWorkspaceProvider } from 'Context/SwitchWorkspaceContext/SwitchWorkspaceContext';
import { WorkspaceProvider } from 'Context/WorkspacesContext/WorkspacesContext';
import initFeatureToggles from 'Services/featureToggles/initFeatureToggles';
import { useJwtProcessing } from 'Utils/useJwtProcessing/useJwtProcessing';
import Routes from './Routes';
import { EnvConfig, EnvProvider } from './components';
import { IntlProvider } from './i18n';

export default function App(props: EnvConfig): React.ReactElement {
  const { optimizelyKey, segmentKey } = props;
  useJwtProcessing();

  initFeatureToggles(optimizelyKey);

  const analyticsService = useAnalyticsService();
  analyticsService.load(segmentKey);

  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <QueryClientProvider client={queryClient}>
          <ClusterProvider>
            <WorkspaceProvider>
              <SwitchWorkspaceProvider>
                <ReactQueryDevtools />
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </SwitchWorkspaceProvider>
            </WorkspaceProvider>
          </ClusterProvider>
        </QueryClientProvider>
      </IntlProvider>
    </EnvProvider>
  );
}
