export enum APIEndpoint {
  GET_VENDORS = 'GET_VENDORS',
  BUILD_CSV = 'BUILD_CSV',
  GET_CSV = 'GET_CSV',
  GET_VENDOR_BY_ID = 'GET_VENDOR_BY_ID',
  SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST',
  GET_REPORTS_MFE_CONFIG = 'GET_REPORTS_MFE_CONFIG',
  GET_EMBED_REPORT = 'GET_EMBED_REPORT',
  GET_REPORT_GROUP = 'GET_REPORT_GROUP',
  GET_REPORT_GROUP_BY_ID = 'GET_REPORT_GROUP_BY_ID',
  UPDATE_REPORT_GROUP_BY_ID = 'UPDATE_REPORT_GROUP_BY_ID',
  ACTIVATE_REPORT_BY_REPORT_ID = 'ACTIVATE_REPORT_BY_REPORT_ID',
  ADD_NEW_REPORT = 'ADD_NEW_REPORT',
  GET_REPORTS_BY_ID = 'GET_REPORTS_BY_ID',
  ADD_NEW_VENDOR = 'ADD_NEW_VENDOR',
  GET_BTGUIDE_BY_ID = 'GET_BTGUIDE_BY_ID',
  GET_REPORT_BY_WORKSPACE_ID = 'GET_REPORT_BY_WORKSPACE_ID',
  GET_VENDORS_BY_ID = 'GET_VENDORS_BY_ID',
  GET_ACTIVATION_VENDORS = 'GET_ACTIVATION_VENDORS',
  GET_DATASETS = 'GET_DATASETS',
  GET_KPIS = 'GET_KPIS',
}

export default APIEndpoint;
