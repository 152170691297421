import CardSection from 'Components/CardSection/CardSection';
import { WorkspaceData } from 'Context/WorkspacesContext/WorkspacesContext';
import { EmbedContent } from 'Pages/EmbedReportsPage/EmbedContent/EmbedContent';
import React from 'react';
import { useIntl } from 'react-intl';

interface BigNumbersSectionProps {
  readonly selectedWorkspace: WorkspaceData;
}

export function BigNumbersSection({
  selectedWorkspace,
}: BigNumbersSectionProps): React.ReactElement {
  const reportId = selectedWorkspace.workspace?.highlightSection?.reportId;
  const { formatMessage } = useIntl();

  return (
    <CardSection
      hasRedirect
      pagePath={reportId}
      redirectText={formatMessage({ id: 'BIG_NUMBERS.BUTTON' })}
      hideHeader
    >
      <EmbedContent
        workspaceId={selectedWorkspace?.workspace?.id ?? ''}
        country={selectedWorkspace.country ?? ''}
        vendorId={selectedWorkspace.vendorId}
        powerBiReportId={selectedWorkspace.workspace?.highlightSection?.powerBiConfig?.reportId}
        powerBiWorkspaceId={
          selectedWorkspace.workspace?.highlightSection?.powerBiConfig?.workspaceId
        }
        reportId={selectedWorkspace.workspace?.highlightSection?.reportId}
        hasBigNumbersSection
      />
    </CardSection>
  );
}
