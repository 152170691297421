import { DownloadIcon } from 'Pages/MainPage/MainPageContent/MainPageContent';
import React from 'react';

export interface CsvDownload {
  csvExportCode: JSX.Element;
  type: string;
  title: string;
}

export interface Dataset {
  title: string;
  reportId: string;
  csvDownload: CsvDownload;
}

export interface BackendResponse {
  datasets: Dataset[];
}

/* Remove when have backend */
/* istanbul ignore next */
const createDataset = ({
  title,
  reportId,
  csvTitle,
}: {
  title: string;
  reportId: string;
  csvTitle: string;
}): Dataset => ({
  title,
  reportId,
  csvDownload: {
    csvExportCode: <DownloadIcon csvDownload={[]} />,
    type: 'CSV',
    title: csvTitle,
  },
});

/* Remove when have backend */
/* istanbul ignore next */
export const backendResponse: BackendResponse = {
  datasets: [
    createDataset({ title: 'Dataset 1', reportId: '123', csvTitle: 'CSV 1' }),
    createDataset({ title: 'Dataset 2', reportId: '456', csvTitle: 'CSV 2' }),
    createDataset({ title: 'Dataset 3', reportId: '789', csvTitle: 'CSV 3' }),
    createDataset({ title: 'Dataset 4', reportId: '101', csvTitle: 'CSV 4' }),
    createDataset({ title: 'Dataset 5', reportId: '112', csvTitle: 'CSV 5' }),
    createDataset({ title: 'Dataset 6', reportId: '131', csvTitle: 'CSV 6' }),
    createDataset({ title: 'Dataset 7', reportId: '415', csvTitle: 'CSV 7' }),
    createDataset({ title: 'Dataset 8', reportId: '161', csvTitle: 'CSV 8' }),
    createDataset({ title: 'Dataset 9', reportId: '718', csvTitle: 'CSV 9' }),
    createDataset({ title: 'Dataset 10', reportId: '192', csvTitle: 'CSV 10' }),
    createDataset({ title: 'Dataset 11', reportId: '193', csvTitle: 'CSV 11' }),
  ],
};
