import { Heading, IconButton, Paragraph } from '@hexa-ui/components';
import { HelpCircle, X } from '@hexa-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FabContent } from './FabContent/FabContent';
import { FabTooltip } from './FabTooltip/FabTooltip';
import { Styled } from './FloatingActionButton.style';

export function FloatingActionButton(): JSX.Element {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const windowRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      windowRef.current &&
      !windowRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <Styled.Button ref={buttonRef} onClick={handleToggle}>
        <HelpCircle />
        <FabTooltip>
          <Styled.Paragraph>{formatMessage({ id: 'FAB.TOOLTIP_MESSAGE_1' })}</Styled.Paragraph>
          <Styled.Paragraph>{formatMessage({ id: 'FAB.TOOLTIP_MESSAGE_2' })}</Styled.Paragraph>
        </FabTooltip>
      </Styled.Button>

      {isOpen && (
        <Styled.FabWindow ref={windowRef} open={isOpen}>
          <Styled.Wrapper>
            <Styled.Header>
              <Heading size="H3">{formatMessage({ id: 'FAB.TITLE' })}</Heading>
              <IconButton
                data-testid="close-button"
                variant="tertiary"
                size="small"
                icon={X}
                onClick={handleToggle}
              />
            </Styled.Header>
            <Paragraph css={{ opacity: '56%' }}>
              {formatMessage({ id: 'FAB.DESCRIPTION' })}
            </Paragraph>
            <FabContent />
          </Styled.Wrapper>
        </Styled.FabWindow>
      )}
    </>
  );
}
