import { Pagination, Paragraph, SearchField } from '@hexa-ui/components';
import EmptyTableImage from 'Assets/images/emptyillustration.svg';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { BackendResponse } from '../../../domain/DatasetTable.types';
import {
  EmptyMessageWrapper,
  PaginationWrapper,
  SearchFieldWrapper,
  ZebraTable,
} from './DatasetTable.style';

function DatasetTable({ datasets }: BackendResponse): JSX.Element {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const { formatMessage } = useIntl();
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };
  const pageSize = 10;

  const filteredDatasets = datasets.filter((dataset) =>
    dataset.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const transformedDatasets = filteredDatasets.map((dataset) => ({
    name: dataset.title,
    type: dataset.csvDownload.type,
    actions: dataset.csvDownload.csvExportCode,
  }));

  const paginatedData = transformedDatasets.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  /* istanbul ignore next */
  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const emptyMessage = (
    <EmptyMessageWrapper>
      <img src={EmptyTableImage} alt="Empty table" />
      <Paragraph data-testid="empty-message">
        {formatMessage({ id: 'DATASET_TABLE.EMPTY_MESSAGE' })}
      </Paragraph>
    </EmptyMessageWrapper>
  );

  return (
    <>
      <SearchFieldWrapper>
        <SearchField.Root
          width="min(445px, 100%)"
          value={searchTerm}
          onChange={handleSearchChange}
          onClear={() => setSearchTerm('')}
          placeholder={formatMessage({ id: 'DATASET_TABLE.SEARCH' })}
        />
      </SearchFieldWrapper>
      <ZebraTable>
        <thead>
          <tr>
            <th style={{ width: '55%' }}>Name</th>
            <th style={{ width: '40%' }}>Data type</th>
            <th style={{ width: '5%', textAlign: 'right' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((dataset) => (
              <tr key={dataset.name}>
                <td>{dataset.name}</td>
                <td>{dataset.type}</td>
                <td style={{ textAlign: 'right' }}>{dataset.actions}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>{emptyMessage}</td>
            </tr>
          )}
        </tbody>
      </ZebraTable>
      <PaginationWrapper>
        <Pagination
          current={currentPage}
          pageSize={10}
          total={transformedDatasets.length}
          showPageSizeSelector={false}
          onChange={changePage}
        />
      </PaginationWrapper>
    </>
  );
}

export default DatasetTable;
