import { Paragraph } from '@hexa-ui/components';
import useSelectedTab from 'Hooks/useSelectedTab/useSelectedTab';
import React from 'react';
import { useIntl } from 'react-intl';
import { ToggleStyles } from './ContentReportsToggleView.style';

const ContentReportsToggleView = (): React.ReactElement | null => {
  const { formatMessage } = useIntl();
  const selectedTab = useSelectedTab();

  return (
    <ToggleStyles>
      <div className="container">
        {['ORDERS', 'INSIGHTS'].includes(selectedTab.reportCode) && (
          <Paragraph size="basis">
            {formatMessage({ id: 'REPORTS_PAGE.CONTENT_REPORTS_TAB_VIEW' })}
          </Paragraph>
        )}
      </div>
    </ToggleStyles>
  );
};

export default ContentReportsToggleView;
