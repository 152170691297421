/* eslint-disable react/display-name */
import { Alert, Heading, Paragraph, Select } from '@hexa-ui/components';
import AppModal from 'Components/AppModal/AppModal';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import {
  AlertMessage,
  ContainerSelect,
  SelectInput,
  SelectMonth,
  SelectPeriodLabel,
  SelectSeller,
  SelectYear,
  Space,
} from './CSVDownloadModal.style';
import { CSVDownloadModalProps } from './CSVDownloadModal.types';

const monthsInYear = 12;

function CSVDownloadModal({
  isOpen,
  onClose,
  onConfirm,
  error,
  isLoading,
  setError,
  indexCSVDownload,
  csvDownload,
}: Readonly<CSVDownloadModalProps>): React.ReactElement {
  const { formatMessage } = useIntl();
  const currentYear = String(new Date().getUTCFullYear());
  const currentMonth = format(new Date(), 'M');
  const tabTitle = csvDownload[indexCSVDownload]?.title['en-US'];
  const { workspaceData } = useWorkspaceContext();

  const isManufacturer = workspaceData?.workspace?.rowLevelSecurity.role === 'Manufacturer';

  /* istanbul ignore next */
  const role = isManufacturer ? 'MANUFACTURER' : undefined;

  const availableYears = useMemo(() => {
    const previousYear = String(Number(currentYear) - 1);

    return [previousYear, currentYear];
  }, [currentYear]);

  const [selectedYear, setSelectedYear] = useState<string>(currentYear);
  const [sellerId, setSellerId] = useState<string | undefined>(undefined);

  const { data: allReportsMenuItems } = useGetReportsByWorkspace(
    workspaceData?.workspace?.id,
    workspaceData?.segment?.id,
    workspaceData?.segment?.country,
    workspaceData?.vendorId,
    role
  );

  const availableMonthsByYear: Record<string, { id: string; label: string }[]> = useMemo(() => {
    const getMonths = (year: string) => {
      const months = [];

      if (year === currentYear) {
        for (let i = 1; i <= parseInt(currentMonth); i++) {
          months.push({ id: String(i), label: formatMessage({ id: `MONTH.${i}` }) });
        }
      } else {
        for (let i = parseInt(currentMonth); i <= monthsInYear; i++) {
          months.push({ id: String(i), label: formatMessage({ id: `MONTH.${i}` }) });
        }
      }

      return months;
    };

    return availableYears.reduce(
      (result, year) => ({
        ...result,
        [year]: getMonths(year),
      }),
      {}
    );
  }, [availableYears, currentMonth, currentYear, formatMessage]);

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const availableSellers = allReportsMenuItems?.vendorRelationship?.sellers || [];

  /* istanbul ignore next */
  const handleChangeYear = (year: string) => {
    setError('');

    const firstMonthAvailable = availableMonthsByYear[year][0].id;
    setSelectedMonth(year === currentYear ? currentMonth : firstMonthAvailable);

    setSelectedYear(year);
  };

  /* istanbul ignore next */
  const handleChangeMonth = (month: string) => {
    setError('');
    setSelectedMonth(month);
  };

  /* istanbul ignore next */
  const handleChangeSeller = (id: string) => {
    setError('');

    setSellerId(id);
  };

  const handleCloseModal = () => {
    /* istanbul ignore next */
    if (isLoading) {
      return;
    }

    setError('');

    setSelectedMonth(currentMonth);
    setSelectedYear(currentYear);
    onClose();
  };

  const vendorId = availableSellers.length === 1 ? availableSellers[0].vendorId : sellerId;

  const handleConfirm = () => {
    onConfirm(
      {
        year: Number(selectedYear),
        month: Number(selectedMonth),
      },
      vendorId
    );
  };

  const renderOptions = (options: string[]) =>
    options.map((value) => (
      <Select.Option data-testid={`select-item-${value}`} key={value} value={value}>
        {value}
      </Select.Option>
    ));

  const renderOptionsMonth = (options: { label: string; id: string }[]) =>
    options.map(({ label, id }) => (
      <Select.Option data-testid={`select-item-${label}`} key={label} value={id}>
        {label}
      </Select.Option>
    ));

  /* istanbul ignore next */
  const renderOptionsSeller = (options: { name: string; vendorId: string }[]) =>
    options.map(({ name, vendorId }) => (
      <Select.Option data-testid={`select-item-${name}`} key={name} value={vendorId}>
        {name}
      </Select.Option>
    ));
  return (
    <AppModal
      isOpen={isOpen}
      onCancelButton={handleCloseModal}
      onConfirmButton={handleConfirm}
      onClose={handleCloseModal}
      buttonConfirmLabel="SELECT_RANGE_DATE_CSV_MODAL.DOWNLOAD"
      buttonCancelLabel="SELECT_RANGE_DATE_CSV_MODAL.CANCEL"
      isLoading={isLoading}
      title="SELECT_RANGE_DATE_CSV_MODAL.TITLE"
      dataTestId="csv-modal"
      css={{
        '@tablet': { minWidth: '640px' },
        width: '100vw',
        '@media (min-width: 600px)': {
          width: '60vw',
        },
        '@media (min-width: 900px)': {
          width: '40vw',
        },
        '@media (min-width: 1200px)': {
          width: '30vw',
        },
      }}
    >
      <SelectPeriodLabel>
        <Paragraph size="basis" className="modal-description" data-testid="modal-description">
          {`${formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.DESCRIPTION' })} ${tabTitle}`}
        </Paragraph>
      </SelectPeriodLabel>
      {error && (
        <AlertMessage>
          <Alert
            data-testid="error-alert"
            type="error"
            message={formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.NO_INFO_AVAILABLE' })}
            className="alert-message"
          />
        </AlertMessage>
      )}
      {!error && <Space />}
      <ContainerSelect>
        <SelectYear>
          <Heading size="H5">
            {formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.YEAR_LABEL' })}
          </Heading>
          <SelectInput>
            <Select.Root data-testid="select-year" value={selectedYear} onChange={handleChangeYear}>
              {renderOptions(availableYears)}
            </Select.Root>
          </SelectInput>
        </SelectYear>
        <SelectMonth>
          <Heading size="H5">
            {formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.MONTH_LABEL' })}
          </Heading>
          <SelectInput>
            <Select.Root
              data-testid="select-month"
              value={selectedMonth}
              onChange={handleChangeMonth}
            >
              {renderOptionsMonth(availableMonthsByYear[selectedYear])}
            </Select.Root>
          </SelectInput>
        </SelectMonth>
      </ContainerSelect>
      {
        /* istanbul ignore next */
        availableSellers.length >= 1 && isManufacturer && (
          <ContainerSelect>
            <SelectSeller>
              <Heading size="H5">
                {formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.SELLER_LABEL' })}
              </Heading>
              <SelectInput>
                <Select.Root
                  data-testid="select-seller"
                  value={vendorId}
                  onChange={handleChangeSeller}
                  placeholder={
                    availableSellers.length === 1
                      ? availableSellers[0].name
                      : formatMessage({ id: 'SELECT_RANGE_DATE_CSV_MODAL.SELECT' })
                  }
                  disabled={availableSellers.length === 1}
                >
                  {renderOptionsSeller(availableSellers)}
                </Select.Root>
              </SelectInput>
            </SelectSeller>
          </ContainerSelect>
        )
      }
      {/* <CsvFileGuideLink /> */}
    </AppModal>
  );
}

export default CSVDownloadModal;
