import { styled } from '@hexa-ui/theme';

const Tooltip = styled('span', {
  visibility: 'hidden',
  position: 'absolute',
  zIndex: 999,
  right: '60px',
  top: '50%',
  transform: 'translateY(-50%)',
  opacity: 0,
  transition: 'opacity 0.3s',
});

const TooltipContent = styled('div', {
  backgroundColor: 'black',
  color: '#fff',
  textAlign: 'center',
  borderRadius: '8px',
  padding: '8px 16px',
  whiteSpace: 'normal',
  width: 'max-content',
});

const TooltipArrow = styled('div', {
  position: 'absolute',
  top: '50%',
  right: '-5px',
  transform: 'translateY(-50%)',
  width: '0',
  height: '0',
  borderLeft: '5px solid black',
  borderTop: '5px solid transparent',
  borderBottom: '5px solid transparent',
});

export const Styled = {
  Tooltip,
  TooltipContent,
  TooltipArrow,
};
