import useSegment from 'Hooks/useSegment/useSegment';
import { useSegmentProperties } from '../useSegmentProperties/useSegmentProperties';
import {
  FilterInteractionPayload,
  FilterSelected,
  LoadingTimeEventReturn,
} from './useLoadingTimeEvent.types';

const filterSelectedMapping: Record<string, FilterSelected> = {
  '30 dias': FilterSelected['30_DAYS'],
  '90 dias': FilterSelected['90_DAYS'],
  'Por PDV': FilterSelected.PER_POC,
  'Por Pedido': FilterSelected.PER_ORDER,
  State: FilterSelected.STATE,
  MTD: FilterSelected.MTD,
  MoM: FilterSelected.MOM,
  SKU: FilterSelected.SKU,
  CD: FilterSelected.DC,
  Estado: FilterSelected.STATE,
  DDC: FilterSelected.DC,
  'Per Order': FilterSelected.PER_ORDER,
  'Per POC': FilterSelected.PER_POC,
  '30 días': FilterSelected['30_DAYS'],
  '90 días': FilterSelected['90_DAYS'],
  '30 days': FilterSelected['30_DAYS'],
  '90 days': FilterSelected['90_DAYS'],
};

export default function useLoadingTimeEvent(): LoadingTimeEventReturn {
  const {
    filtersInteraction,
    dataLoadedRenderingProcess,
    reportRenderedRenderingProcess,
    rederingStartedRenderingProcess,
    initRenderingProcess,
    interactionRenderedRenderingProcess,
  } = useSegment();

  const {
    app_display_name,
    app_id,
    origin_workspace_label,
    origin_workspace_tracking_id,
    page_url,
    user_affiliation,
    user_country,
    user_id,
    user_email,
    vendor_display_name,
    vendor_id,
    vendor_service_model,
    vendor_tier_name,
    origin_report_label,
    origin_report_tracking_id,
    origin_theme_label,
    origin_theme_tracking_id,
    page_label,
    vendor_country,
  } = useSegmentProperties();

  const triggerFilterInteraction = ({
    analytics_component_id,
    analytics_component_name,
    filter_selected,
  }: FilterInteractionPayload) => {
    /* istanbul ignore next */
    if (analytics_component_name.startsWith('Filter ')) {
      filtersInteraction({
        analytics_component_category: null,
        filter_selected: filterSelectedMapping[filter_selected] || null,
        searched_word: null,
        analytics_component_id,
        analytics_component_name,
      });
    }
  };

  const triggerDataLoadedRendering = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    dataLoadedRenderingProcess({
      app_display_name,
      app_id,  
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,      
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      origin_workspace_label,
      origin_workspace_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_report_label,
      origin_report_tracking_id, 
      occurence_id,
    });
  };

  const triggerReportRenderedProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    reportRenderedRenderingProcess({
      app_display_name,
      app_id,  
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,      
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      origin_workspace_label,
      origin_workspace_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_report_label,
      origin_report_tracking_id, 
      occurence_id,
    });
  };

  const triggerRenderingStartedRenderingProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    rederingStartedRenderingProcess({
      app_display_name,
      app_id,  
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,      
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      origin_workspace_label,
      origin_workspace_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_report_label,
      origin_report_tracking_id, 
      occurence_id,
    });
  };

  const triggerInitRenderingProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    initRenderingProcess({
      app_display_name,
      app_id,  
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,      
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      origin_workspace_label,
      origin_workspace_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_report_label,
      origin_report_tracking_id, 
      occurence_id,
    });
  };

  const triggerInteractionRenderedProcess = ({
    occurence_id,
    page_name,
    is_core_event,
  }: {
    occurence_id: string;
    page_name: string;
    is_core_event: boolean;
  }) => {
    interactionRenderedRenderingProcess({
      app_display_name,
      app_id,  
      is_core_event,
      page_label,
      page_name,
      page_url,
      user_country,      
      user_email,
      user_id,
      user_affiliation,
      vendor_country,
      vendor_display_name,
      vendor_id,
      vendor_service_model,
      vendor_tier_name,
      origin_workspace_label,
      origin_workspace_tracking_id,
      origin_theme_label,
      origin_theme_tracking_id,
      origin_report_label,
      origin_report_tracking_id, 
      occurence_id,
    });
  };

  return {
    triggerFilterInteraction,
    triggerDataLoadedRendering,
    triggerReportRenderedProcess,
    triggerRenderingStartedRenderingProcess,
    triggerInitRenderingProcess,
    triggerInteractionRenderedProcess,
  };
}
