import { Paragraph } from '@hexa-ui/components';
import { ChevronDown, ChevronUp } from '@hexa-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Styled } from './ExpandableCard.style';
import { ExpandableCardProps } from './ExpandableCard.types';

function ExpandableCard({
  title,
  children,
  isExpanded,
  onToggle,
}: ExpandableCardProps): React.ReactElement {
  const [maxHeight, setMaxHeight] = useState<string>('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMaxHeight(isExpanded ? `${contentRef?.current?.scrollHeight}px` : '0px');
  }, [isExpanded]);

  return (
    <Styled.Wrapper>
      <Styled.Item onClick={onToggle} data-testid="expandable-card-title">
        <Paragraph weight="medium">{title}</Paragraph>
        {isExpanded ? <ChevronUp /> : <ChevronDown />}
      </Styled.Item>
      <div
        ref={contentRef}
        style={{
          maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease',
        }}
      >
        <Styled.Content>{children}</Styled.Content>
      </div>
    </Styled.Wrapper>
  );
}

export default ExpandableCard;
