import APIEndpoint from './APIEndpoint';
import APIService from './APIService';

const getServiceByEndpoint = (endpoint: APIEndpoint): APIService | null => {
  const servicesByEndpoint: Record<APIService, APIEndpoint[]> = {
    BFF: [
      APIEndpoint.GET_VENDORS,
      APIEndpoint.BUILD_CSV,
      APIEndpoint.GET_VENDOR_BY_ID,
      APIEndpoint.GET_REPORTS_MFE_CONFIG,
      APIEndpoint.GET_CSV,
      APIEndpoint.GET_EMBED_REPORT,
      APIEndpoint.GET_REPORT_GROUP,
      APIEndpoint.ACTIVATE_REPORT_BY_REPORT_ID,
      APIEndpoint.UPDATE_REPORT_GROUP_BY_ID,
      APIEndpoint.GET_REPORT_GROUP_BY_ID,
      APIEndpoint.ADD_NEW_REPORT,
      APIEndpoint.GET_REPORTS_BY_ID,
      APIEndpoint.ADD_NEW_VENDOR,
      APIEndpoint.GET_BTGUIDE_BY_ID,
      APIEndpoint.GET_REPORT_BY_WORKSPACE_ID,
      APIEndpoint.GET_VENDORS_BY_ID,
      APIEndpoint.GET_ACTIVATION_VENDORS,
    ],
    partnerRequestService: [APIEndpoint.SEND_EMAIL_REQUEST],
  };

  for (const [service, endpoints] of Object.entries(servicesByEndpoint)) {
    if (endpoints.includes(endpoint)) {
      return service as APIService;
    }
  }

  return null;
};

export default getServiceByEndpoint;
