import Api from 'Api/Api';
import APIEndpoint from 'Api/APIEndpoint';
import getAPIBaseURL from 'Api/getAPIBaseURL';
import { DatasetsResponse } from 'Domain/DatasetTable.types';

export type getDatasetsProps = {
  country?: string;
  workspaceId?: string;
  permissionId?: string;
};

export const getDatasets = async ({
  country,
  workspaceId,
  permissionId,
}: getDatasetsProps): Promise<DatasetsResponse> => {
  const BASE_URL = getAPIBaseURL(APIEndpoint.GET_DATASETS, country);

  const headers: Record<string, string> = {};
  if (country) {
    headers.country = country;
  }

  const datasets = 'reports/datasets';

  const response = await Api.get({
    url: `config/${datasets}`,
    baseURL: BASE_URL,
    params: {
      workspaceId,
      permissionId,
    },
    headers,
  });

  return response.data as DatasetsResponse;
};
