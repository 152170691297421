/* eslint-disable max-params */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { MenuItensResponse } from 'Domain/NewMenuItem';
import { getReportsByWorkspace } from './getReportsByWorkspace';

export const useGetReportsByWorkspace = (
  workspaceId?: string,
  permissionId?: string,
  country?: string,
  vendorId?: string,
  role?: string
): UseQueryResult<MenuItensResponse> =>
  useQuery({
    queryKey: ['reportsByWorkspace', workspaceId, permissionId, country, vendorId, role],
    queryFn: () =>
      getReportsByWorkspace(
        workspaceId as string,
        country as string,
        permissionId as string,
        vendorId as string,
        role as string
      ),
    enabled: !!workspaceId,
    refetchOnWindowFocus: false,
    staleTime: 7500,
  });
